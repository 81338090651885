import Dashboard from "layouts/dashboard";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import MainDashBoard from "layouts/main_dashboard";
import CreateTemplate from "layouts/DynamicForm/create-template";
import ViewTemplate from "layouts/DynamicForm/view-template";
import Icon from "@mui/material/Icon";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Pricing from "layouts/Pricing";
import SentimentAnalysis from "layouts/sentimentanalysis";
import GamepadIcon from "@mui/icons-material/Gamepad";
import SurveyAnalysis from "layouts/surveyanalysis";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Billing from "layouts/billing";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMaterialUIController } from "context";
import CreateUser from "layouts/configuration/CreateDynamicUser";
import ViewSurveyData from "layouts/DynamicForm/view-surveyData";
const isAuthenticated2 = sessionStorage.getItem('isAuthenticated') === 'true';


const RoutesConfig = () => {
  const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
  console.log("==========", isAuthenticated);
  console.log("----------", isAuthenticated2);
  const routes = [
    {
      type: false,
      name: "Dashboard",
      key: "dashboard",
      icon: <Icon fontSize="small">dashboard</Icon>,
      route: "/dashboard",
      component: isAuthenticated ? <MainDashBoard /> : <Navigate to="/login" />,
    },
    {
      type: true,
      name: "Analysis",
      key: "analysis",
      icon: <Icon fontSize="small">analytics</Icon>,
      component: null,
      children: [
        {
          type: false,
          name: "Sentiment Analysis",
          key: "sentimentanalysis",
          icon: <Icon fontSize="small">sentiment_very_satisfied</Icon>,
          // icon: <FiberManualRecordIcon fontSize="small" />,
          route: "/sentimentanalysis",
          ischild: true,
          component: isAuthenticated ? (
            <SentimentAnalysis />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          type: false,
          name: "Survey Analysis",
          key: "surveyanalysis",
          icon: <AnalyticsIcon fontSize="small" color="inherit" />,
          route: "/analysissurvey",
          ischild: true,
          component: isAuthenticated ? (
            <SurveyAnalysis />
          ) : (
            <Navigate to="/login" />
          ),
        },
      ],
    },
    {
      type: false,
      name: "Notifications",
      key: "notifications",
      icon: <Icon fontSize="small">notifications</Icon>,
      route: "/notifications",
      component: isAuthenticated ? <Notifications /> : <Navigate to="/login" />,
    },
    {
      type: false,
      name: "Profile",
      key: "profile",
      icon: <Icon fontSize="small">person</Icon>,
      route: "/profile",
      component: isAuthenticated ? <Profile /> : <Navigate to="/login" />,
    },
    {
      type: true,
      name: "Dynamic Form",
      key: "dynamicform",
      icon: <DynamicFormIcon fontSize="small" />,
      children: [
        {
          type: false,
          name: "Create Template",
          key: "create-template",
          icon: <Icon fontSize="small">add_circle</Icon>,
          route: "/dynamicform/create-template",
          ischild: true,
          component: isAuthenticated ? (
            <CreateTemplate />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          type: false,
          name: "View Template",
          key: "view-template",
          icon: <Icon fontSize="small">visibility</Icon>,
          route: "/dynamicform/view-template",
          ischild: true,
          component: isAuthenticated ? (
            <ViewTemplate />
          ) : (
            <Navigate to="/login" />
          ),
        },
        {
          type: false,
          name: "View Survey Data",
          key: "view-survey-data",
          icon: <Icon fontSize="small">visibility</Icon>,
          route: "/dynamicform/view-survey-data",
          ischild: true,
          component: isAuthenticated ? (
            <ViewSurveyData />
          ) : (
            <Navigate to="/login" />
          ),
        },
      ],
    },
   
    {
      type: true,
      name: "Subscriptions",
      key: "subscriptions",
      icon: <AttachMoneyIcon fontSize="small" />,
      children: [
        {
          type: false,
          name: "Pricing",
          key: "pricing",
          icon: <Icon fontSize="small">receipt_long</Icon>,
          route: "/pricing",
          ischild: true,
          component: isAuthenticated ? <Pricing /> : <Navigate to="/login" />,
        },
        {
          type: false,
          name: "Billing",
          key: "billing",
          icon: <Icon fontSize="small">receipt_long</Icon>,
          route: "/billing",
          ischild: true,
          component: isAuthenticated ? <Billing /> : <Navigate to="/login" />,
        },
      ],
    },
    {
      type: true,
      name: "Configuration",
      key: "configuration",
      icon: <GamepadIcon fontSize="small" />,
      component: null,
      children: [
        {
          type: false,
          name: "Create Survey User",
          key: "create-dynamic-user",
          icon: <PersonAddIcon fontSize="small" color="inherit" />,
          route: "/create-dynamic-user",
          ischild: true,
          component: isAuthenticated ? <CreateUser /> : <Navigate to="/login" />,
        },
      ]
    },
  ];
  return routes;
};


export default (RoutesConfig);


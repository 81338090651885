// authSlice.js
import { createSlice } from "@reduxjs/toolkit";
import sessionStorage from "redux-persist/es/storage/session";

const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  isLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginStart: (state) => {
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
      state.token = action.payload.token;
      state.isLoading = false;
    },
    loginFailure: (state) => {
      state.isLoading = false;
      state.isAuthenticated = false;
    },
    logout: (state, action) => {
    
      state.isAuthenticated = false;
      state.user = null;
      state.token = null;
      Object.assign(state, initialState);
    },
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    stateClear: (state) => {
     
      Object.assign(state, initialState);
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  setIsAuthenticated,
  stateClear,
} = authSlice.actions;

export default authSlice.reducer;

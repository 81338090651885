import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleLoginButton from './GoogleLoginButton';
import PropTypes from 'prop-types';

const GoogleSignIn = ({ onSuccess, onError }) => {
    return (
        <GoogleOAuthProvider clientId="150168978281-jhavjk6h44u6hicpt7rer2g13l3u6meq.apps.googleusercontent.com">
            <div>
                <GoogleLoginButton onSuccess={onSuccess} onError={onError} buttonText="Google" />
            </div>
        </GoogleOAuthProvider>
    );
};

// Add propTypes validation
GoogleSignIn.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
};

export default GoogleSignIn;

import React from 'react';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import MDBox from 'components/MDBox';
import { Grid } from '@mui/material';
import PieChart from 'examples/Charts/PieChart';
import pieChartData from 'layouts/dashboard/data/pieChartData';
import DefaultLineChart from 'examples/Charts/LineCharts/DefaultLineChart';
import defaultLineChartData from 'layouts/dashboard/data/DefaultLineChartData';

const SurveyAnalysis = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox  py={4}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={5}>
            <MDBox mb={3}>
              <PieChart
                title="Pie Chart"
                description="Number of Surveys Conducted vs. Attempted"
                chart={pieChartData}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <MDBox mb={3}>
              <DefaultLineChart
                color="warning"
                title="Line Chart"
                description="This is an example of a line chart."
                chart={defaultLineChartData}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default SurveyAnalysis;



// function collapseItem(theme, ownerState) {
//   const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
//   const { active, transparentSidenav, whiteSidenav, darkMode, sidenavColor, miniSidenav,ischild } = ownerState;

//   const { white, transparent, dark, grey, gradients } = palette;
//   const { md, xxl } = boxShadows;
//   const { borderRadius } = borders;
//   const { pxToRem, rgba, linearGradient } = functions;

//   const newSideNavColor = [
//     { primary: '#d81b60' },
//     { secondary: "#495361" },
//     { info: '#1b74e9' },
//     { success: '#43a047' },
//     { warning: '#fb8c00' },
//     { error: '#e53935' },
//     { dark: '#191919' },
//   ];

//   const getbgColor = (sidenavColor, newColor) => {
//     let requiredColor;
//     newColor.map(color => {
//       const key = Object.keys(color)[0];
//       const colorValues = color[key];
//       if (sidenavColor === key) {
//         requiredColor = colorValues;
//       }
//     });
//     return requiredColor;
//   }

//   const activeBackground = sidenavColor && gradients[sidenavColor]
//     ? linearGradient(gradients[sidenavColor].main, gradients[sidenavColor].state)
//     : transparent.main;

//   const hoverBackgroundColor = !active
//     ? transparentSidenav && !darkMode
//       ?rgba(getbgColor(sidenavColor, newSideNavColor)) 
//       : rgba(whiteSidenav ? getbgColor(sidenavColor, newSideNavColor) :  getbgColor(sidenavColor, newSideNavColor), 1)
//     : undefined;

//   return {
//     position: "relative",
//     background: active ? activeBackground : transparent.main,
//     color:
//       (transparentSidenav && !darkMode && !active) || (whiteSidenav && !active)
//         ? dark.main
//         : white.main,
//     display: "flex",
//     alignItems: "center",
//     justifyContent: 'center',
//     width: "100%",
//     height: '100%',
//     padding: '10px 0px',
//     margin: `${pxToRem(4.5)} ${pxToRem(16)}`,
//     borderRadius: borderRadius.lg,
//     cursor: "pointer",
//     userSelect: "none",
//     whiteSpace: "nowrap",
//     boxShadow: active && !whiteSidenav && !darkMode && !transparentSidenav
//       ? "rgba(0, 0, 0, 0.4) 0px 30px 90px;"
//       : "none",
//     overflow: "hidden",
//     zIndex: 1,
//     [breakpoints.up("xl")]: {
//       transition: transitions.create(["box-shadow", "background-color", "border-radius"], {
//         easing: transitions.easing.easeInOut,
//         duration: transitions.duration.shorter,
//       }),
//     },

 

//     "&:hover, &:focus": {
//       color: white.main,
//       backgroundColor: hoverBackgroundColor,
//       boxShadow: miniSidenav ? 'rgba(0, 0, 0, 0.56) 0px 10px 70px 4px' : 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px', // Added 3D shadow effect
//       transform: "translateX(15px)", // Slightly lifts the item to create a 3D effect
//       transition: transitions.create(["box-shadow", "transform", "background-color"], {
//         easing: transitions.easing.easeIn,
//         duration: transitions.duration.shorter,
//       }),
//     },

    
//   };
// }
function collapseItem(theme, ownerState) {
  const { palette, transitions, breakpoints, boxShadows, borders, functions } = theme;
  const { active, transparentSidenav, whiteSidenav, darkMode, sidenavColor, miniSidenav, ischild } = ownerState;

  const { white, transparent, dark, grey, gradients } = palette;
  const { md, xxl } = boxShadows;
  const { borderRadius } = borders;
  const { pxToRem, rgba, linearGradient } = functions;

  const newSideNavColor = [
    { primary: '#d81b60' },
    { secondary: "#495361" },
    { info: '#1b74e9' },
    { success: '#43a047' },
    { warning: '#fb8c00' },
    { error: '#e53935' },
    { dark: '#191919' },
  ];

  const getbgColor = (sidenavColor, newColor) => {
    let requiredColor;
    newColor.map(color => {
      const key = Object.keys(color)[0];
      const colorValues = color[key];
      if (sidenavColor === key) {
        requiredColor = colorValues;
      }
    });
    return requiredColor;
  }

  const activeBackground = sidenavColor && gradients[sidenavColor]
    ? linearGradient(gradients[sidenavColor].main, gradients[sidenavColor].state)
    : transparent.main;

  const hoverBackgroundColor = !active
    ? transparentSidenav && !darkMode
      ? rgba(getbgColor(sidenavColor, newSideNavColor))
      : rgba(whiteSidenav ? getbgColor(sidenavColor, newSideNavColor) : getbgColor(sidenavColor, newSideNavColor), 1)
    : undefined;

  return {
    position: "relative",
    background: active ? activeBackground : transparent.main,
    color:
      (transparentSidenav && !darkMode && !active) || (whiteSidenav && !active)
        ? dark.main
        : white.main,
    display: "flex",
    alignItems: "center",
    justifyContent: 'center',
    width: "100%",
    height: '100%',
    padding: '10px 0px',
    margin: !miniSidenav ? ischild ? `${pxToRem(4.5)} ${pxToRem(30)}` : `${pxToRem(4.5)} ${pxToRem(16)}`: `${pxToRem(4.5)} ${pxToRem(16)}`,
    borderRadius: borderRadius.lg,
    cursor: "pointer",
    userSelect: "none",
    whiteSpace: "nowrap",
    boxShadow: active && !whiteSidenav && !darkMode && !transparentSidenav
      ? "rgba(0, 0, 0, 0.4) 0px 30px 90px;"
      : "none",
    overflow: "hidden",
    zIndex: 1,
    [breakpoints.up("xl")]: {
      transition: transitions.create(["box-shadow", "background-color", "border-radius"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.shorter,
      }),
    },
    
    "&::before": ischild ? {
      content: '""',
      display: 'block',
      width: pxToRem(6),
      height: pxToRem(6),
      backgroundColor: dark.main,
      borderRadius: '50%',
      position: 'absolute',
      left: pxToRem(-10),
    } : {},

    "&:hover, &:focus": {
      color: white.main,
      backgroundColor: hoverBackgroundColor,
      boxShadow: miniSidenav ? 'rgba(0, 0, 0, 0.56) 0px 10px 70px 4px' : 'rgba(17, 12, 46, 0.15) 0px 48px 100px 0px',
      transform: "translateX(15px)",
      transition: transitions.create(["box-shadow", "transform", "background-color"], {
        easing: transitions.easing.easeIn,
        duration: transitions.duration.shorter,
      }),
    },

 
  };
}


function collapseIconBox(theme, ownerState) {
  const { palette, transitions, borders, functions } = theme;
  const { transparentSidenav, whiteSidenav, darkMode, active, miniSidenav } = ownerState;

  const { white, dark } = palette;
  const { borderRadius } = borders;
  const { pxToRem } = functions;

  return {
    minWidth: pxToRem(32),
    minHeight: pxToRem(32),
    color:
      (transparentSidenav && !darkMode && !active) || (whiteSidenav && !active)
        ? dark.main
        : white.main,
    borderRadius: borderRadius.md,
    display: "grid",
    placeItems: "center",
    transition: transitions.create("margin", {
      easing: transitions.easing.easeInOut,
      duration: transitions.duration.standard,
    }),

    "&:hover,&:focus":{
      color:
      (transparentSidenav && !darkMode && !active) || (whiteSidenav && !active)
        ? white.main
        : dark.main,
    },

    "& svg, svg g": {
      color: transparentSidenav || whiteSidenav ? dark.main : white.main,
    },
  };
}

const collapseIcon = ({ palette: { white, gradients } }, { active }) => ({
  color: active ? white.main : gradients.dark.state,
});

function collapseText(theme, ownerState) {
  const { typography, transitions, breakpoints, functions } = theme;
  const { miniSidenav, transparentSidenav, active } = ownerState;

  const { size, fontWeightRegular, fontWeightLight } = typography;
  const { pxToRem } = functions;

  return {
    marginLeft: pxToRem(10),

    [breakpoints.up("xl")]: {
      opacity: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : 1,
      maxWidth: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : "100%",
      marginLeft: miniSidenav || (miniSidenav && transparentSidenav) ? 0 : pxToRem(10),
      transition: transitions.create(["opacity", "margin"], {
        easing: transitions.easing.easeInOut,
        duration: transitions.duration.standard,
      }),
    },

    "& span": {
      fontWeight: active ? fontWeightRegular : fontWeightLight,
      fontSize: size.sm,
      lineHeight: 0,
    },
  };
}

export { collapseItem, collapseIconBox, collapseIcon, collapseText };

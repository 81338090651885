import React, { useState, useRef } from "react";
import { Box, TextField, Button, Link, Divider } from "@mui/material";
import { styled } from "@mui/system";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import sideImage from "assets/images/sideImage.png";
import User from "assets/images/User.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import { useDispatch } from "react-redux";
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { login, googleSignIn } from "../../api/apiCall";
import { loginStart, loginSuccess, loginFailure, logout } from "store/authSlice";
import GoogleSignIn from "../../google-auth/GoogleSignIn"
const Root = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage:
    "linear-gradient(to bottom, #367de8, #2e71d6, #2565c4, #1c5ab2, #124fa1)",
  [theme.breakpoints.down("sm")]: {
    background: "none",
  },
}));

const ImgBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  "& img": {
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const FormBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 32,
  [theme.breakpoints.down("sm")]: {
    padding: 16,
  },
}));

const Form = styled("form")({
  width: "100%",
});

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%",
  },
}));

const SubmitButton = styled(Button)({
  marginTop: 8,
  width: "50%",
  background: "#6C63FF",
  color: "#fff",
  "&:hover": {
    background: "#5a54e8",
  },
});

const Login = () => {
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState({ emailError: false, passwordError: false, errorMessage: "" });
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const renderCount = useRef(1);
  // Increment the render count on each render
  renderCount.current += 1;
  console.log("Login component Render Count: ", renderCount.current);

  const handleLogin = async (event) => {
    event.preventDefault();
    const { email, password } = credentials;
    if (!email || !password) {
      setErrors({
        emailError: !email,
        passwordError: !password,
        errorMessage: "Both fields are required."
      });
      return;
    }
    setErrors({ emailError: false, passwordError: false, errorMessage: "" });
    try {
      const respData = await login(email, password);
      if (respData.status === 200) {
        const { token, data } = respData;
        debugger;
        dispatch(loginSuccess({ user: data, token }));
        // navigate("/dashboard", { replace: true });
        navigate("/dashboard");

      }
    } catch (error) {
      console.error("Error during login:", error?.response?.data?.error || error.message);
      dispatch(loginFailure());
      setErrors({ ...errors, errorMessage: "Login failed. Please try again." });
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
    setErrors({ ...errors, [`${name}Error`]: false });
  };

  const handleSignInSuccess = async (credentialResponse) => {
    // const idToken = credentialResponse.credential;
    const accessToken = credentialResponse.access_token;
    try {
      const respData = await googleSignIn(accessToken);
      if (respData.status === 200) {
        const { token, data } = respData;
        dispatch(loginSuccess({ user: data, token }));
        navigate("/dashboard", { replace: true });
      }
    } catch (error) {
      console.error('Sign-In Failed:', error);
    }
  };

  const handleFailure = (error) => {
    console.error('Authentication Failed:', error);
  };


  return (
    <Root>
      <MDBox
        sx={{
          width: { xs: "100%", sm: "80%", md: "60%" },
          height: { xs: "100%", sm: "80%", md: "80%" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: '1',
          backgroundColor: "#F0F2F5",
          boxShadow: {
            xs: "10px 5px 10px rgba(0, 0, 0, 0.1)",
            sm: "0px 10px 20px rgba(0, 0, 0, 0.1)",
            md: "0px 10px 20px rgba(0, 0, 0, 0.2)",
          },
          borderRadius: '10px',
          position: "relative"
        }}
      >
        <ImgBox>
          <img src={sideImage} alt="background" />
        </ImgBox>
        <Divider orientation="vertical" />
        <MDBox
          sx={{
            width: { xs: "100%", sm: "80%", md: "50%" },
            height: { xs: "90%", sm: "100%", md: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: { xs: 2, sm: 5, md: 2 },
            paddingBottom: { xs: 2, sm: 5, md: 2 },
            paddingLeft: { xs: 2, sm: 5, md: 4 },
            paddingRight: { xs: 2, sm: 5, md: 4 },
            textAlign: "center",
            borderRadius: '6px',
            boxShadow: {
              xs: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              sm: "0px 0px 1px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <MDAvatar src={User} alt="avatar" />
          <MDTypography variant="h4" gutterBottom>
            Sign In
          </MDTypography>
          <Form noValidate onSubmit={handleLogin}>
            <InputWrapper>
              <EmailIcon style={{ marginRight: "8px", color: "purple" }} />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={credentials.email}
                onChange={handleChange}
                error={errors.emailError}
                helperText={errors.emailError ? "Email is required." : ""}
              />
            </InputWrapper>
            <InputWrapper>
              <LockIcon style={{ marginRight: "8px", color: "purple" }} />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="new-password"
                value={credentials.password}
                onChange={handleChange}
                error={errors.passwordError}
                helperText={errors.passwordError ? "Password is required." : ""}
              />
            </InputWrapper>
            {error && (
              <MDTypography color="error" variant="body2" sx={{ mt: 2 }}>
                {error}
              </MDTypography>
            )}
            <InputWrapper sx={{ display: "flex", justifyContent: "flex-end" }}>
              <MDTypography variant="caption"
                color={"purple"}
                sx={{
                  fontWeight: 400,
                  fontStyle: "italic"
                }}
              >
                Forgot password?
              </MDTypography>
            </InputWrapper>
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SubmitButton type="submit" variant="contained">
                Sign In
              </SubmitButton>
            </MDBox>
          </Form>
          <MDBox sx={{ display: "flex", justifyContent: "center", marginTop: 1 }}>
            <MDTypography variant="body2" component="h6" fontWeight="light" color="dark">
              Don&apos;t have an account?
            </MDTypography>
            <Link
              to="/register"
              variant="body2"
              sx={{
                color: "purple", cursor: "pointer",
                marginLeft: 1, fontWeight: "500",

              }}
              onClick={() => navigate("/register")}
              onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
              onMouseOut={(e) => e.target.style.textDecoration = 'none'}
            >
              Sign Up
            </Link>
          </MDBox>
          <MDBox
            sx={{ display: "flex", justifyContent: "space-between" }}
            width="100%"
            mt={4}
            mb={1}
          >
            <MDBox
            >
              <GoogleSignIn onSuccess={handleSignInSuccess} onError={handleFailure} />
            </MDBox>
            <MDBox
              borderRadius='4px'
              sx={{
                padding: "0.2rem .8rem",
                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                backgroundColor: "#ffffff",
                width: "40%",
                border: 0,
                flexDirection: "row",
                cursor: "pointer",
                "&:hover": {
                  boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px"
                },
              }}
            >
              <MDTypography variant="body2" color={"purple"}>
                Facebook
              </MDTypography>
              <FacebookIcon />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Root>
  );
};

export default Login;

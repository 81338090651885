// export default {
//   sales: {
//     labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//     datasets: { label: "Mobile apps", data: [50, 40, 300, 320, 500, 350, 200, 230, 500] },
//   },
//   tasks: {
//     labels: ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
//     datasets: { label: "Desktop apps", data: [50, 40, 300, 220, 500, 250, 400, 230, 500] },
//   },
// };
const websiteTrafficData = {
  week: {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: {
      label: "Website Traffic",
      data: [120, 150, 170, 200, 250, 300, 350],
    },
  },
  month: {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: {
      label: "Website Traffic",
      data: [400, 500, 450, 600],
    },
  },
  quarter: {
    labels: ["Jan-Mar", "Apr-Jun", "Jul-Sep", "Oct-Dec"],
    datasets: {
      label: "Website Traffic",
      data: [1200, 1500, 1700, 2000],
    },
  },
  year: {
    labels: ["2020", "2021", "2022", "2023"],
    datasets: {
      label: "Website Traffic",
      data: [5000, 7000, 7500, 8000],
    },
  },
};

export default websiteTrafficData;



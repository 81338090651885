import React from 'react'

import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import { Grid } from '@mui/material'
import VerticalBarChart from 'examples/Charts/BarCharts/VerticalBarChart'
import PieActiveArc from 'examples/Charts/PieActiveArc'
import verticalBarChartData from 'layouts/dashboard/data/verticalBarChartData'

const SentimentAnalysis = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={5}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={3}>
              <VerticalBarChart
                color="success" 
                title="website views"
                description="Last Campaign Performance"
                date="campaign sent 2 days ago"
                chart={verticalBarChartData}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <MDBox mb={3}>
              <PieActiveArc title="Pie Chart" />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  )
}

export default SentimentAnalysis
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Header from "layouts/profile/components/Header";
import MDButton from "components/MDButton";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function Overview() {
  const [profileData, setProfileData] = useState({
    description: "Hi, I’m Alec Thompson, Decisions: If you can’t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality).",
    info: {
      fullName: "J.J. Thomson",
      mobile: "(44) 1234 123",
      email: "jjthomson@mail.com",
      location: "USA",
    },
    social: [
      {
        icon: <FacebookIcon />,
        color: "facebook",
      },
      {
        icon: <TwitterIcon />,
        color: "twitter",
      },
      {
        icon: <InstagramIcon />,
        color: "instagram",
      },
    ],
  });

  const [open, setOpen] = useState(false);
  const [editData, setEditData] = useState({
    description: profileData.description,
    info: { ...profileData.info },
    social:[...profileData.social],
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleChange = (e) => {
    console.log(e.target);
    
    const { name, value } = e.target;
    console.log(name, value)
    setEditData((prevData) => ({
      ...prevData,
      [name.includes("info.") ? "info" : name]: name.includes("info.")
        ? { ...prevData.info, [name.split(".")[1]]: value }
        : value,
    }));
  };

  const handleSave = () => {
    setProfileData(editData);
    handleClose();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
                description={profileData.description}
                info={profileData.info}
                social={profileData.social}
                action={{ route: "", tooltip: "Edit Profile", onClick: handleOpen }}
                shadow={true}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
          </Grid>
        </MDBox>
        <Modal open={open} onClose={handleClose}>
          <Box sx={modalStyle}>
            <MDTypography variant="h6" component="h2">
              Edit Profile
            </MDTypography>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              margin="normal"
              name="description"
              label="Description"
              value={editData.description}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              name="info.fullName"
              label="Full Name"
              value={editData.info.fullName}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              name="info.mobile"
              label="Mobile"
              value={editData.info.mobile}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              name="info.email"
              label="Email"
              value={editData.info.email}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              variant="outlined"
              margin="normal"
              name="info.location"
              label="Location"
              value={editData.info.location}
              onChange={handleChange}
            />
            <MDButton variant="contained" color={"info"} onClick={handleSave}>
              Save
            </MDButton>
          </Box>
        </Modal>
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;



export default {
  labels: ["January", "February", "March", "April", "May", "June", "July"],
  datasets: [
    {
      label: "Dataset 1",
      color: "success",
      data: [65, 59, 80, 81, 56, 55, 40],
    },
    {
      label: "Dataset 2",
      color: "info",
      data: [28, 48, 40, 19, 86, 27, 90],
    },
    {
      label: "Dataset 3",
      color: "warning",
      data: [18, 38, 30, 29, 66, 27, 70],
    },
  ],
};

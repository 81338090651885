import { Card, Paper } from "@mui/material";
import { useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React from "react";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import { Grid, MenuItem, Select } from "@mui/material";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DoughnutChartData from "layouts/dashboard/data/DoughnutChartData";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import websiteTrafficData from "layouts/dashboard/data/websiteTrafficData";
import SubscriptionDetailCard from "examples/Cards/InfoCards/SubscriptionDetailCard";
import subscriptiondata from "layouts/dashboard/data/subscriptiondata"
const MainDashBoard = () => {
  const [timePeriod, setTimePeriod] = useState("week");

  const handleTimePeriodChange = (event) => {
    setTimePeriod(event.target.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox py={3}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="poll"
                title="Total Survey Conducted"
                subtitle="last 28 day"
                count="197"
                percentage={{ color: "success", amount: "+55%", label: "than last month" }}
                more="/analysissurvey"
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="mood"
                title="Positive"
                subtitle="last 28 day"
                count="76"
                percentage={{ color: "success", amount: "+3%", label: "than last month" }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="mood_bad"
                title="Negative"
                subtitle="last 28 day"
                count="103"
                percentage={{ color: "success", amount: "+5%", label: "than last month" }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="sentiment_neutral"
                title="Neutral"
                subtitle="last 28 day"
                count="12"
                percentage={{ color: "success", amount: "+3%", label: "than last month" }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={1.5}>
                <DefaultDoughnutChart
                  icon={{ color: "primary", component: "pie_chart" }}
                  title="Total Sentiment"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={DoughnutChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={8}>
              <MDBox sx={{ backgroundColor: "#FFFFFF" }}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                  <MDTypography variant="h6" textTransform="capitalize"
                    sx={{ margin: "5px 10px" }}>
                    Website Traffic
                  </MDTypography>
                  <Select
                    value={timePeriod}
                    onChange={handleTimePeriodChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ marginRight: 2, marginTop: 2, padding: "5px 10px", borderRadius: "10px", backgroundColor: "#f8f9fa", color: "#6c757d" }}
                  >
                    <MenuItem value="week">Week</MenuItem>
                    <MenuItem value="month">Month</MenuItem>
                    <MenuItem value="quarter">Quarter</MenuItem>
                    <MenuItem value="year">Year</MenuItem>
                  </Select>
                </MDBox>
                <ReportsLineChart
                  chart={websiteTrafficData[timePeriod]}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox mt={5} mb={3} sx={{ backgroundColor: "#FFFFFF" }}>
          <MDTypography variant="h4" textTransform="capitalize" sx={{ padding: 3, }}>
            Subscription Overview
          </MDTypography>
          <MDBox mt={2} mb={2} sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              {subscriptiondata.map((subscription) => (
                <Grid item xs={12} sm={6} md={4} key={subscription.id}>
                  <SubscriptionDetailCard
                    color="info"
                    icon={subscription.icon}
                    title={subscription.title}
                    description={subscription.description}
                    used={subscription.used}
                    total={subscription.total}
                    duration={subscription.duration}
                    date={subscription.date}
                    status={subscription.status}
                  />
                </Grid>
              ))}
            </Grid>

          </MDBox>

        </MDBox>

      </MDBox>
    </DashboardLayout>
  );
};

export default MainDashBoard;

import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Card from "@mui/material/Card";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import configs from "examples/Charts/PieChart/configs";
import MDSelect from "components/MDSelect";
import Icon from "@mui/material/Icon";
import { FormControl } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart({ title, description, height, chart }) {
  const [timeframe, setTimeframe] = useState("week");

  const handleChange = (event) => {
    setTimeframe(event.target.value);
  };

  const getTimeframeData = (timeframe) => {
    switch (timeframe) {
      case "week":
        return chart.week;
      case "month":
        return chart.month;
      case "quarter":
        return chart.quarter;
      case "year":
        return chart.year;
      default:
        return chart.week;
    }
  };

  const { data, options } = configs(getTimeframeData(timeframe).labels, getTimeframeData(timeframe).datasets);

  const labelname = data.labels;
  const labelcolor = data.datasets[0].backgroundColor;

  const labelsWithColors = labelname.map((name, index) => ({
    labelname: name,
    labelcolor: labelcolor[index],
  }));

  const renderChart = (
    <MDBox py={2} pr={2} pl={2} sx={{ height: "530px" }}>
      {title || description ? (
        <MDBox display="flex" flexDirection="column" px={description ? 1 : 0} pt={description ? 1 : 0}>
          <MDBox mt={0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              <MDTypography component="div" variant="button" color="text">
                {description}
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <MDSelect value={timeframe} onChange={handleChange}
                  sx={{
                    width: '6.5rem',
                    height: '2rem',
                    border: '1.5px solid #1976d2',
                    borderRadius: '4px',
                    '&:hover': {
                      borderColor: '#115293',
                    },
                    '&.Mui-focused': {
                      borderColor: '#0d47a1',
                    }
                  }}
                  autoWidth
                  endAdornment={<Icon fontSize="medium">arrow_drop_down</Icon>}
                >
                  <MenuItem value="week">Week</MenuItem>
                  <MenuItem value="month">Month</MenuItem>
                  <MenuItem value="quarter">Quarter</MenuItem>
                  <MenuItem value="year">Year</MenuItem>
                </MDSelect>
              </FormControl>
            </MDBox>
          </MDBox>
        </MDBox>
      ) : null}
      {useMemo(
        () => (
          <MDBox height={height} mt={-2}>
            <Pie data={data} options={options} redraw />
          </MDBox>
        ),
        [chart, height, timeframe]
      )}
    </MDBox>
  );

  return title || description ? (
    <Card>
      {renderChart}
      <MDBox mt={-5} display="flex" marginX="auto">
        {labelsWithColors.map(({ labelname, labelcolor }) => (
          <MDBox key={labelname} display="flex" alignItems="center" mb={1} mr={2}>
            <span
              style={{
                backgroundColor: labelcolor,
                width: "10px",
                height: "10px",
                marginRight: "5px",
                display: "inline-block",
              }}
            ></span>
            <MDTypography component="span" variant="body" color="text" fontWeight="small" fontSize="16px">
              {labelname}
            </MDTypography>
          </MDBox>
        ))}
      </MDBox>
    </Card>
  ) : (
    renderChart
  );
}

PieChart.defaultProps = {
  title: "",
  description: "",
  height: "19.125rem",
};

PieChart.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    )
  ).isRequired,
};

export default PieChart;

import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { FcGoogle } from "react-icons/fc";

const GoogleLoginButton = ({ onSuccess, onError, buttonText }) => {
    const login = useGoogleLogin({
        onSuccess,
        onError,
    });

    return (
        <Button 
            variant="contained" 
            color="white" 
            onClick={() => login()} 
            startIcon={<FcGoogle size="20" />}
            sx={{ borderRadius:"4px", paddingX:4 }}
        >
            {buttonText}
        </Button>
    );
};

// Defining prop types for GoogleLoginButton
GoogleLoginButton.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    onError: PropTypes.func.isRequired,
    buttonText: PropTypes.string.isRequired,
};

export default GoogleLoginButton;

import axios from 'axios';
import { useSelector } from 'react-redux';

// Define the base URLs
// for Local 
// const BASE_URL = 'http://localhost:4000/v1/api';
// for Server 
const BASE_URL = 'http://13.200.199.63:4000/v1/api';

// Define a common headers object
const commonHeaders = {
  'api_key': 'gyanendea765786576ghwvdhd',
  'Content-Type': 'application/json',
};

// Authentication APIs
export const googleSignUp = async (access_token) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/google/signup`, { access_token }, { headers: commonHeaders });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const googleSignIn = async (access_token) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/google/signin`, { access_token }, { headers: commonHeaders });
    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const login = async (email, password) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/login`, { email, password }, { headers: commonHeaders });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

// Form APIs
export const createTemplate = async (tempData) => {
  const token=useSelector((state) => state.auth.token);
  try {
    const response = await axios.post(`${BASE_URL}/form/template/create`, tempData, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const sendEMailtoUsers = async (data) => {
  const token=useSelector((state) => state.auth.token);
  try {
    const response = await axios.post(`${BASE_URL}/form/sent-survey`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getViewTemplateData = async () => {
  const token=useSelector((state) => state.auth.token);
  try {
    debugger;
    const response = await axios.get(`${BASE_URL}/form/viewTemplate`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const getBulkUser = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/user/bulkuser`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const uploadBulkUserForsurvey = async (data, token) => {
  try {
    const response = await axios.post(`${BASE_URL}/form/upload-user`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    });
    console.log('Response Data:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error.response.data;
  }
};

export const openSurvey = async (templateId, userId) => {
  try {
    const response = await axios.get(`${BASE_URL}/auth/openSurvey`, {
      headers: {
        'Content-Type': 'application/json',
        'api_key': 'gyanendea765786576ghwvdhd',
      },
      params: {
        templateId: templateId,
        userId: userId,
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const storeSurveyData = async (surveyData) => {
  try {
    const response = await axios.post(`${BASE_URL}/auth/saveSurvey`, surveyData, {
      headers: {
        'Content-Type': 'application/json',
        'api_key': 'gyanendea765786576ghwvdhd',
      },
    });
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

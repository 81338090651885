import React, { useCallback, useMemo, memo } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { Routes, Route, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import RoutesConfig from "./routes";
import {useMaterialUIController,setOpenConfigurator,
} from "context";
import Businesslogo from "assets/images/Businesslogo.png";
import Login from "layouts/auth/Login";
import Register from "layouts/auth/Register";
import SurveyForm from "layouts/DynamicForm/survey-form";
import MainDashBoard from "layouts/main_dashboard";
const App = () => {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const { pathname } = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isLoading = useSelector((state) => state.auth.isLoading);
  const routes = RoutesConfig();





  const getRoutes = useCallback((allRoutes) =>
    allRoutes.flatMap((route) =>
      route.children
        ? route.children.map((childRoute) => (
          <Route
            path={childRoute.route}
            element={childRoute.component}
            key={childRoute.key}
          />
        ))
        : <Route path={route.route} element={route.component} key={route.key} />
    ),
    []
  );

  // const surveypath = "/survey";

  if (isLoading) {
    return <div>Loading...</div>;
  }
  // console.log('layout',layout)
  console.log('isAuthenticated33333333',isAuthenticated)
  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
    
      {/* {isAuthenticated && layout === "dashboard" && !pathname.includes(surveypath) && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? Businesslogo
                : Businesslogo
            }
            brandName="Shiavnshki"
            routes={routes}
          />
          <Configurator />
          {configsButton}
        </>
      )}
     */}
      <Routes>
        {getRoutes(routes)}
        <Route path="/" element={isAuthenticated ? <MainDashBoard/> : <Login/>} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register/>} />
        <Route path="/surveyform" element={<SurveyForm/>} />
      </Routes>
    </ThemeProvider>
  );
};

export default memo(App);

import PropTypes from "prop-types";
import Link from "@mui/material/Link";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import typography from "assets/theme/base/typography";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link) => (
      <MDBox key={link.name} component="li" px={2} lineHeight={1}>
        <Link href={link.href} target="_blank">
          <MDTypography variant="button" fontWeight="regular" color="text">
            {link.name}
          </MDTypography>
        </Link>
      </MDBox>
    ));

  return (
    <footer style={{ position: "fixed",width: "100%", bottom: 0 }}>
      <MDBox
        // width="100%"
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        alignItems="center"
        px={1.5}
      >
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color="text"
          fontSize={size.sm}
          px={1.5}
        >
          &copy; {new Date().getFullYear()},
          <Link href={href} target="_blank">
            <MDTypography variant="button" fontWeight="medium">
              &nbsp;{name}&nbsp;
            </MDTypography>
          </Link>
          . All rights reserved.
        </MDBox>
      </MDBox>
    </footer>
  );
}

Footer.defaultProps = {
  company: { name: "Shiavnshki" },
};

Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;

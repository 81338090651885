
import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
const data = [
  { id: 0, value: 10, label: 'Positive' },
  { id: 1, value: 15, label: 'Negative' },
  { id: 2, value: 20, label: 'Neutral' },
];

export default function PieActiveArc() {
  return (
    <Card>
      <MDBox py={10} px={2} pl={2} sx={{ height: "450px" }}>
        <PieChart
          series={[
            {
              data,
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
            },
          ]}
          height={300}
          label={({ dataEntry }) => (
            <text
              x={dataEntry.style.x}
              y={dataEntry.style.y + 20} // Adjust this value to position label correctly
              fill={dataEntry.style.textColor}
              textAnchor="middle"
              dominantBaseline="middle"
              fontSize={12}
            >
              {dataEntry.data.label}
            </text>
          )}
        />
      </MDBox>
    </Card>
  );
}

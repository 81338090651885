import React, { useState } from "react";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import data from "layouts/dashboard/components/Projects/data";

import { styled } from "@mui/system";
import InputBase from "@mui/material/InputBase";

const SearchContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  borderRadius: 8,
  backgroundColor: "#f2f2f2",
  padding: "6px 12px",
});

const SearchInput = styled(InputBase)({
  marginLeft: 8,
  flex: 1,
});

const initialSortState = {
  field: "",
  order: "asc",
};

function Projects() {
  const { columns, rows } = data();
  const [menu, setMenu] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [sort, setSort] = useState(initialSortState);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const filteredRows = rows.filter((row) =>
    row.product.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSort = (field) => {
    setSort((prevSort) => ({
      field,
      order: prevSort.field === field && prevSort.order === "asc" ? "desc" : "asc",
    }));
  };

  const renderSortIcon = (field) => {
    if (sort.field === field) {
      return sort.order === "asc" ? <Icon>arrow_upward</Icon> : <Icon>arrow_downward</Icon>;
    }
    return null;
  };

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Review Table
          </MDTypography>
        </MDBox>
        <SearchContainer>
          <SearchInput placeholder="Search..." value={searchTerm} onChange={handleSearchChange} />
          <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
            more_vert
          </Icon>
        </SearchContainer>
        {renderMenu}
      </MDBox>
      <MDBox>
        <DataTable
          table={{
            columns: columns.map((column) => ({
              ...column,
              label: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  {column.label}
                  {column.sortable && (
                    <Icon onClick={() => handleSort(column.field)} style={{ cursor: "pointer" }}>
                      {renderSortIcon(column.field)}
                    </Icon>
                  )}
                </div>
              ),
            })),
            rows: filteredRows.sort((a, b) => {
              if (sort.field === "") return 0;
              const aValue = a[sort.field].toString().toLowerCase();
              const bValue = b[sort.field].toString().toLowerCase();
              return sort.order === "asc"
                ? aValue.localeCompare(bValue)
                : bValue.localeCompare(aValue);
            }),
          }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

export default Projects;

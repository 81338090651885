import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import SmsIcon from '@mui/icons-material/Sms';
import DaysIcon from '@mui/icons-material/EventAvailable';

const SubscriptionData = [
    {
        id:1,
        title:"Email",
        status:"Active",
        icon:<EmailIcon defaultsize="large"/>,
        total:"100",
        used:"40",
        duration:"1 month",
        description:"Email Subscription",
        date:"01/01/2021",
    },
    {
        id:2,
        title:"SMS",
        status:"Active",
        icon:<SmsIcon defaultsize="large" />,
        total:"100",
        used:"40",
        duration:"1 month",
        description:"SMS Subscription",
        date:"01/01/2021",
    },
    {
        id:3,
        title:"Whatsapp",
        status:"Active",
        icon:<WhatsAppIcon  defaultsize="large" />,
        total:"100",
        used:"40",
        duration:"1 month",
        description:"Whatsapp Subscription",
        date:"01/01/2021",
    },
    {
        id:4,
        title:"days",
        status:"Active",
        icon:<DaysIcon  defaultsize="large" />,
        total:"30",
        used:"18",
        duration:"1 month",
        description:"Days Subscription",
        date:"01/01/2021",
    }
]

export default SubscriptionData
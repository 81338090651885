// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function SubscriptionDetailCard({ color, icon, title, description, used, total, duration, date, status }) {
  return (
    <Card 
    sx={{boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;"}}>
      <MDBox p={2} mx={3} display="flex" justifyContent="center" 
      >
        <MDBox
          display="grid"
          justifyContent="center"
          alignItems="center"
          bgColor={color}
          color="white"
          width="4rem"
          height="4rem"
          shadow="md"
          borderRadius="lg"
          variant="gradient"
        >
            {icon}
        </MDBox>
      </MDBox>
      <MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
        {description && (
          <MDTypography variant="caption" color="text" fontWeight="regular">
            {description}
          </MDTypography>
        )}
        <Divider />
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Used: {used} / {total}
        </MDTypography>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Remaining: {total - used}
        </MDTypography>
        <MDTypography variant="caption" color="text" fontWeight="regular">
          Duration: {duration}
        </MDTypography>
        <MDTypography variant="caption" color="text" fontWeight="regular">
          Date: {date}
        </MDTypography>
        <Divider />
        {status==="Active"? 
        (
            <MDTypography variant="caption" color="success" fontWeight="regular">
          {status}
          </MDTypography>
        ): 
        (
            <MDTypography variant="caption" color="error" fontWeight="regular">
          {status}
          </MDTypography>
        )}
   
         
        
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of SubscriptionDetailCard
SubscriptionDetailCard.defaultProps = {
  color: "info",
  used: "",
  total: "",
  description: "",
  duration: "",
  date: "",
  status: "",
};

// Typechecking props for the SubscriptionDetailCard
SubscriptionDetailCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  used: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  duration: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
};

export default SubscriptionDetailCard;



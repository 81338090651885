// // store.js

// import { configureStore } from '@reduxjs/toolkit';
// import { combineReducers } from 'redux';
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import { persistReducer, persistStore } from 'redux-persist';
// import {thunk} from 'redux-thunk'; // Updated import
// import authReducer from './authSlice';

// const persistConfig = {
//   key: 'root',
//   storage,
// };

// const rootReducer = combineReducers({
//   auth: authReducer,
//   // Add other reducers if any
// });

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// const store = configureStore({
//   reducer: persistedReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
// });

// const persistor = persistStore(store);

// export { store, persistor };


import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage/session'; // use sessionStorage
import { persistReducer, persistStore } from 'redux-persist';
import {thunk} from 'redux-thunk'; // Corrected import
import authReducer from './authSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = combineReducers({
  auth: authReducer,
  // Add other reducers if any
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(thunk),
});

const persistor = persistStore(store);

export { store, persistor };

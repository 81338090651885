import React, { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";

const PricingBlock = ({ iconClass, title, price, features, delay }) => {
  const [showAllFeatures, setShowAllFeatures] = useState(false);

  const displayFeatures = showAllFeatures ? features : features.slice(0, 5);

  return (
    <div
      className={`pricing-block col-lg-4 col-md-6 col-sm-12 wow fadeInUp ${
        delay ? `wow-delay-${delay}` : ""
      }`}
    >
      <div className="inner-box">
        <div>
          <div className="icon-box">
            <div className="icon-outer">
              <i className={iconClass}></i>
            </div>
          </div>
          <div className="price-box">
            <div className="title">{title}</div>
            <h4 className="price">{price}</h4>
          </div>
          <ul className="features">
            {displayFeatures.map((feature, index) => (
              <li key={index} className={feature.included ? "true" : "false"}>
                {feature.text}
              </li>
            ))}
          </ul>
        </div>
        {features.length > 5 && (
          <div className="btn-box">
            <button
              onClick={() => setShowAllFeatures(!showAllFeatures)}
              className="theme-btn"
            >
              {showAllFeatures ? "Show less" : "Show more"}
            </button>
          </div>
        )}
        <div className="btn-box">
          <a href="#" className="theme-btn">
            BUY plan
          </a>
        </div>
      </div>
    </div>
  );
};

PricingBlock.propTypes = {
  iconClass: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      included: PropTypes.bool.isRequired,
    })
  ).isRequired,
  delay: PropTypes.string,
};

export default PricingBlock;

import { useEffect, useState } from "react";
import { useLocation, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import { IconButton } from "@mui/material";
import { navbarMobileMenu, sidenavbarMobileMenu } from "examples/Navbars/DashboardNavbar/styles";
import ScrollContainer from "./styles/ScrollContainer";



function Sidenav({ color, light, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    transparentNavbar,
    sidenavColor,
  } = controller;
  const location = useLocation();
  const [activeRoute, setActiveRoute] = useState(location.pathname);
  const [openRoutes, setOpenRoutes] = useState({});



  const handleToggle = (key) => {
    setOpenRoutes((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleRouteClick = (route) => {
    setActiveRoute(route);
  };

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: light || darkMode ? white.main : dark.main,
    ...(transparentNavbar &&
      !light && { color: darkMode ? rgba(text.main, 0.6) : text.main }),
  });

  useEffect(() => {
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200 || true);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }

    window.addEventListener("resize", handleMiniSidenav);
    handleMiniSidenav();

    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, transparentSidenav, whiteSidenav]);

  const renderRoutes = (routes) => {
    return routes.map(
      ({
        type,
        name,
        icon,
        title,
        noCollapse,
        key,
        href,
        ischild,
        route,
        children,
      }) => {
        let returnValue;

        const isActive = route === activeRoute;

        if (children && children.length > 0) {
          returnValue = (
            <MDBox key={key}>
              <Tooltip title={name} placement="right">
                <MDBox onClick={() => handleToggle(key)}>
                  <SidenavCollapse
                    name={name}
                    icon={icon}
                    active={isActive}
                    hasChildren={true}
                  />
                </MDBox>
              </Tooltip>
              {openRoutes[key] && <List pl={2}>{renderRoutes(children)}</List>}
            </MDBox>
          );
        } else {
          if (href) {
            returnValue = (
              <Tooltip title={name} placement="right" key={key}>
                <Link
                  href={href}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ textDecoration: "none" }}
                  onClick={() => handleRouteClick(route)}
                >
                  <SidenavCollapse
                    name={name}
                    icon={icon}
                    active={isActive}
                    noCollapse={noCollapse}
                  />
                </Link>
              </Tooltip>
            );
          } else {
            returnValue = (
              <Tooltip title={name} placement="right" key={key}>
                <NavLink to={route} onClick={() => handleRouteClick(route)}>
                  {ischild ? (
                    <SidenavCollapse
                      name={name}
                      icon={icon}
                      active={isActive}
                      ischild={ischild}
                    />
                  ) : (
                    <SidenavCollapse
                      name={name}
                      icon={icon}
                      active={isActive}
                    />
                  )}
                </NavLink>
              </Tooltip>
            );
          }
        }

        if (type === "title") {
          returnValue = (
            <Tooltip title={title} placement="right" key={key}>
              <MDTypography
                color={textColor}
                display="block"
                variant="caption"
                fontWeight="bold"
                textTransform="uppercase"
                pl={3}
                mt={2}
                mb={1}
                ml={1}
              >
                {title}
              </MDTypography>
            </Tooltip>
          );
        } else if (type === "divider") {
          returnValue = (
            <Divider
              key={key}
              light={
                (!darkMode && !whiteSidenav && !transparentSidenav) ||
                (darkMode && !transparentSidenav && whiteSidenav)
              }
            />
          );
        }

        return returnValue;
      }
    );
  };

  return (
  
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox
        pt={3}
        pb={1}
        px={4}
        textAlign="center"
        sx={{
          position: "sticky",
          zIndex: 3,
          width: "100%",
          top: 0,
          backgroundColor: "inherit",
          
        }}
      >
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox display="flex" alignItems="center">
          {miniSidenav ? (
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={sidenavbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon sx={iconsStyle} fontSize="medium">
                menu_close
              </Icon>
            </IconButton>
          ) : (
            <MDBox component="img" src={brand} alt="Brand" width="2rem" />
          )}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => ({
              ...sidenavLogoLabel(theme, { miniSidenav }),
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            })}
          >
            <MDTypography
              component="h6"
              variant="button"
              fontWeight="medium"
              color={textColor}
            >
              {brandName}
            </MDTypography>
            <IconButton
              size="small"
              disableRipple
              color="inherit"
              sx={sidenavbarMobileMenu}
              onClick={handleMiniSidenav}
            >
              <Icon sx={iconsStyle} fontSize="medium">
                menu_open
              </Icon>
            </IconButton>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
        <ScrollContainer> 
       
           <List sx={{marginTop:"5px"}}>{renderRoutes(routes)}</List>
      </ScrollContainer>
    </SidenavRoot>

  );
}

Sidenav.defaultProps = {
  color: "info",
  light: false,
  brand: "",
};

Sidenav.propTypes = {
  light: PropTypes.bool,
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;

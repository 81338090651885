import React from "react";
import style from "./style.css";
import PricingBlock from "./PricingBlock";
import { Box } from "@mui/material";

const PricingSection = () => {
  return (
    <section className="pricing-section">
      <div className="container">
        <div className="sec-title text-center">
          <span className="title">Get plan</span>
          <h2>Choose a Plan</h2>
        </div>

        <div className="outer-box">
          <div className="row cards">
            <PricingBlock
              iconClass="fas fa-paper-plane"
              title="Starter Pass"
              price="$9/month"
              features={[
                { text: "Up to 100 surveys per month", included: true },
                { text: "1000 responses per month", included: true },
                { text: "Basic survey template", included: true },
                { text: "Access to basic analytics", included: true },
                { text: "Email support", included: true },
                { text: "Integration with 3rd party tools", included: false },
                {text:"Custom branding option",included:false}
              ]}
            />
            <PricingBlock
              iconClass="fas fa-gem"
              title="Pro Pass"
              price="$29.99/month"
              features={[
                { text: "Up to 500 surveys per month", included: true },
                { text: "10000 responses per month", included: true },
                { text: "Custom survey template", included: true },
                { text: "Advanced analytics and reporting", included: true },
                { text: "Priority email support", included: true },
                { text: "Integration with 3rd party tools", included: true },
                { text: "Advanced security features (SSO,data,encryption)", included: false },
                {text:"Custom branding option",included:true}
              ]}
              delay="400ms"
            />
            <PricingBlock
              iconClass="fas fa-rocket"
              title="Enterprise"
              price="$99.99/month"
              features={[
                { text: "Unlimited surveys per month", included: true },
                { text: "Unlimited responses per month", included: true },
                { text: "Premium survey template and customization", included: true },
                { text: "Advanced analytics, reporting and insights", included: true },
                { text: "24/7 phone and email support", included: true },
                { text: "Integration with 3rd party tools", included: true },
                { text: "Advanced security features (SSO,data,encryption)", included: true },
                {text:"Custom onboarding and training sessions",included:true},
              ]}
              delay="800ms"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingSection;

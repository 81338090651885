// surveyData.js

const DoughnutChartData = {
  labels: ["Very Satisfied", "Satisfied", "Neutral", "Dissatisfied", "Very Dissatisfied"],
  datasets: {
    label: "Survey Results",
    data: [40, 30, 15, 10, 5],
    backgroundColor: ["#4caf50", "#8bc34a", "#ffeb3b", "#ff9800", "#f44336"],
  },

};

export default DoughnutChartData;

import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Link,
  Divider,
  Grid
} from "@mui/material";
import { styled } from "@mui/system";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate } from "react-router-dom";
import sideImage from "assets/images/sideImage.png";
import User from "assets/images/User.png";
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleSignUp from '../../google-auth/GoogleSignUp'; // Make sure to import your GoogleSignUp component
// ---------- MD Component ------------------
import MDBox from "components/MDBox";
import MDAvatar from "components/MDAvatar";
import MDTypography from "components/MDTypography";
import { googleSignUp } from '../../api/apiCall';

const Root = styled("div")(({ theme }) => ({
  height: "100vh",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundImage:
    "linear-gradient(to bottom, #367de8, #2e71d6, #2565c4, #1c5ab2, #124fa1)",
  [theme.breakpoints.down("sm")]: {
    background: "none",
  },
}));

const ImgBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flex: 1,
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
  "& img": {
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const FormBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 32,
  [theme.breakpoints.down("sm")]: {
    padding: 16,
  },
}));

const Form = styled("form")({
  width: "100%",
});

const InputWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    width: "100%",
  },
}));

const SubmitButton = styled(Button)({
  marginTop: 8,
  width: "50%",
  background: "#6C63FF",
  color: "#fff",
  "&:hover": {
    background: "#5a54e8",
  },
});

const Register = () => {
  const navigate = useNavigate();
  const [enteredEmail, setEnteredEmail] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [enteredName, setEnteredName] = useState("");
  const [enteredPhone, setEnteredPhone] = useState("");
  const [error, setError] = useState("");

  const defaultEmail = "abc@gmail.com";
  const defaultPassword = "1111";
  

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic validation
    if (enteredPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Check credentials (for demo purposes)
    if (enteredEmail === defaultEmail && enteredPassword === defaultPassword) {
      navigate("/login");
    } else {
      setError("Invalid email or password. Please try again.");
    }
  };
  const handleSignUpSuccess = async (credentialResponse) => {
    console.log("credentialResponse",credentialResponse)
    // const idToken = credentialResponse.credential;
    const accessToken = credentialResponse.access_token;
    debugger;
    try {
      if(accessToken !="" || accessToken !=null){
        const response = await googleSignUp(accessToken);
        debugger;
        console.log('Sign-Up Successful:', response);
        
      }else{
        alert("Google Id Token Not FOund ")
      }
       
        // Store user data in your application's state or context
    } catch (error) {
        console.error('Sign-Up Failed:', error);
    }
};

const handleFailure = (error) => {
    console.error('Authentication Failed:', error);
};

  
  return (
    <Root>
      <MDBox
        sx={{
          width: { xs: "100%", sm: "80%", md: "60%" },
          height: { xs: "100%", sm: "80%", md: "80%" },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: '1',
          backgroundColor: "#F0F2F5",
          boxShadow: {
            xs: "10px 5px 10px rgba(0, 0, 0, 0.1)",
            sm: "0px 10px 20px rgba(0, 0, 0, 0.1)",
            md: "0px 10px 20px rgba(0, 0, 0, 0.2)",
          },
          borderRadius: '10px',
          position: "relative"
        }}
      >
        <ImgBox>
          <img src={sideImage} alt="background" />
        </ImgBox>
        <Divider orientation="vertical" />
        <MDBox
          sx={{
            width: { xs: "100%", sm: "80%", md: "50%" },
            height: { xs: "90%", sm: "100%", md: "100%" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: { xs: 2, sm: 5, md: 2 },
            paddingBottom: { xs: 2, sm: 5, md: 2},
            paddingLeft: { xs: 2, sm: 5, md: 4 },
            paddingRight: { xs: 2, sm: 5, md: 4 },
            textAlign: "center",
            borderRadius: '6px',
            boxShadow: {
              xs: "0px 2px 5px rgba(0, 0, 0, 0.1)",
              sm: "0px 0px 1px rgba(0, 0, 0, 0.1)",
            },
          }}
        >
          <MDAvatar src={User} size={"sm"} alt="avatar" />
          <MDTypography variant="h5" s gutterBottom>
            Sign Up
          </MDTypography>
          <Form noValidate onSubmit={handleSubmit}>
            <Grid container>
              <Grid item xs={12} sm={6} md={12}>
                <InputWrapper>
                  <PersonIcon style={{ marginRight: "6px", color: "purple" }} />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="name"
                    label="Full Name"
                    name="name"
                    size="small"
                    value={enteredName}
                    onChange={(e) => setEnteredName(e.target.value)}
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <InputWrapper>
                  <EmailIcon style={{ marginRight: "8px", color: "purple" }} />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    size={"small"}
                    autoComplete="email"
                    value={enteredEmail}
                    onChange={(e) => setEnteredEmail(e.target.value)}
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <InputWrapper>
                  <PhoneIcon style={{ marginRight: "8px", color: "purple" }} />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="phone"
                    label="Phone Number"
                    name="phone"
                    size="small"
                    value={enteredPhone}
                    onChange={(e) => setEnteredPhone(e.target.value)}
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputWrapper>
                  <LockIcon style={{ marginRight: "8px", color: "purple" }} />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    size="small"
                    autoComplete="new-password"
                    value={enteredPassword}
                    onChange={(e) => setEnteredPassword(e.target.value)}
                  />
                </InputWrapper>
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputWrapper>
                  <LockIcon style={{ marginRight: "5px", color: "purple" }} />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    size="small"
                    autoComplete="new-password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </InputWrapper>
              </Grid>
            </Grid>

            {error && (
              <MDTypography color="error" variant="body2" sx={{ mt: 2 }}>
                {error}
              </MDTypography>
            )}
            <MDBox
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <SubmitButton type="submit" variant="contained">
                Sign Up
              </SubmitButton>
            </MDBox>
          </Form>
          <MDBox sx={{ display: "flex", justifyContent: "center",marginTop:1 }}>
            <MDTypography variant="body2"component="h6" fontWeight="500" color="dark">
              Already have an account?
            </MDTypography>
            <Link
              to="/register"
              variant="body2"
              sx={{color: "purple", cursor: "pointer",
                marginLeft:1,fontWeight:"500" ,
                
              }}
              onClick={() => navigate("/login")}
              onMouseOver={(e) => e.target.style.textDecoration = 'underline'}
              onMouseOut={(e) => e.target.style.textDecoration = 'none'}
            >
               Sign In
            </Link>
          </MDBox>
          <MDBox
            sx={{ display: "flex", justifyContent: "space-between" }}
            width="100%"
            mt={1}
            mb={1}
          >
            <MDBox
            >
            {/* <GoogleSignUp/> */}
            <GoogleSignUp onSuccess={handleSignUpSuccess} onError={handleFailure} />
            </MDBox>
            <MDBox
              borderRadius='4px'
              sx={{
                padding: "0.2rem .8rem",
                boxShadow: "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                backgroundColor:"#ffffff",
                width: "40%",
                border:0,
                flexDirection: "row",
                cursor: "pointer",
                "&:hover":{
                  boxShadow:" rgba(0, 0, 0, 0.24) 0px 3px 8px"
                },
              }}
            >
              <MDTypography variant="h6"  fontWeight="regular" sx={{color:"#989898"}}>
                Facebook
              </MDTypography>
              <FacebookIcon />
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>
    </Root>
  );
};

export default Register;

import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    Box,
    Typography,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Button,
    Container,
    Paper,
    Grid,
    MenuItem,
    Checkbox,
} from '@mui/material';
import { keyframes } from '@mui/material';
import { styled } from '@mui/material/styles';
import formsubmitAnimation from "assets/images/formsubmitAnimation.json";
import urlImage from '../../../assets/images/urlImage.png';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import MDBox from 'components/MDBox';
import MDSelect from 'components/MDSelect';
import MDButton from 'components/MDButton';
import { openSurvey, storeSurveyData } from "../../../api/apiCall";

const Root = styled('div')(({ theme, isFilled }) => ({
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#3173b1',
    backgroundSize: isFilled ? 'cover' : 'contain',
    backgroundPosition: 'center',
    transition: 'background-size 1s ease-in-out',
    [theme.breakpoints.down('md')]: {
        background: 'none',
    },
    fontFamily: 'Roboto, sans-serif',
}));

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fillBackground = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const SurveyForm = () => {
    const [formData, setFormData] = useState({});
    const [formElements, setFormElements] = useState([]);
    const [templateName, setTemplateName] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [templateBgColor, setTemplateBgColor] = useState("");
    const [isFilled, setIsFilled] = useState(false);
    const [message, setMessage] = useState("");
    const [code, setCode] = useState(2); // Default to show form

    const query = useQuery();
    const tempId = query.get('_t');
    const userId = query.get('_u');
    console.log('templateId:', tempId, 'userid', userId);

    useEffect(() => {
        fetchTemplate();
    }, [tempId, userId]);

    const fetchTemplate = async () => {
        try {
            const response = await openSurvey(tempId, userId);
            console.log(response);
            const { success, code, message, data } = response;

            setCode(code);
            setMessage(message);

            if (success && code === 2) {
                setTemplateName(data.name);
                setFormElements(data.template_details);
                setTemplateBgColor(data.bg_color);

                const initialFormData = data.template_details.reduce((acc, element) => {
                    if (element.type === 'checkbox') {
                        acc[element.id] = [];
                    } else {
                        acc[element.id] = '';
                    }
                    return acc;
                }, {});
                setFormData(initialFormData);
            }
        } catch (error) {
            console.error('Error fetching template:', error);
            setCode(1);
            setMessage("Internal Server Error: An error occurred while fetching the survey form.");
        }
    };

    const handleChange = (e) => {
        const { name, value, checked, type } = e.target;

        setFormData(prevState => {
            if (type === 'checkbox') {
                const updatedArray = checked
                    ? [...prevState[name], value]
                    : prevState[name].filter(option => option !== value);
                return { ...prevState, [name]: updatedArray };
            } else {
                return { ...prevState, [name]: value };
            }
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const surveyData = Object.keys(formData).map(key => ({
            questionId: key,
            value: Array.isArray(formData[key]) ? formData[key].join(', ') : formData[key],
        }));

        const payload = {
            templateId: tempId,
            userId: userId,
            surveyData: surveyData,
        };
        try {
            const response = await storeSurveyData(payload);
            setFormData({});
            setIsSubmitted(true);
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    // Prevent navigation away from the page after form submission
    useEffect(() => {
        if (isSubmitted) {
            window.history.pushState(null, null, window.location.href);
            window.onpopstate = () => {
                window.history.pushState(null, null, window.location.href);
            };
        }
    }, [isSubmitted]);

    // Trigger an animation or UI change after a delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsFilled(true);
        }, 4500); // Duration of the fill animation

        return () => clearTimeout(timer);
    }, [isFilled]);

    return (
        <Root isFilled={isFilled}>
            {isSubmitted ? (
                <Typography variant="h4" align="center" sx={{ color: '#fff' }}>
                    Form submitted successfully!
                </Typography>
            ) : code === 1 ? (
                <Typography variant="h4" align="center" sx={{ color: '#fff' }}>
                    {message}
                </Typography>
            ) : (
                <MDBox
                    maxWidth="md"
                    component={Paper}
                    bgColor={templateBgColor}
                    sx={{
                        padding: 3,
                        mt: 3,
                        mx: 'auto',
                        width: '80%',
                        height: '100%',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        fontFamily: 'Roboto, sans-serif',
                    }}
                >
                    <Typography variant="h3" gutterBottom align="center" fontWeight={600} sx={{ fontFamily: 'Roboto, sans-serif' }}>
                        {templateName}
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                        <img
                            src={urlImage}
                            alt="Form Image"
                            style={{
                                height: '30%',
                                width: '90%',
                                borderRadius: '10px',
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                            }}
                        />
                    </Box>

                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            {formElements.map((element) => (
                                <Grid item xs={12} key={element.id}>
                                    <MDBox
                                        component={Paper}
                                        elevation={3}
                                        sx={{
                                            padding: 2,
                                            mx: 'auto',
                                            backgroundColor: 'transparent',
                                            borderRadius: 2,
                                        }}
                                        bgColor={"white"}
                                    >
                                        {element.type === 'full name' && (
                                            <TextField
                                                fullWidth
                                                label={element.label}
                                                name={element.id}
                                                value={formData[element.id] || ''}
                                                onChange={handleChange}
                                                variant="outlined"
                                                required
                                                sx={{
                                                    backgroundColor: 'white',
                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: 'black',
                                                        opacity: 1,
                                                    },
                                                }}
                                            />
                                        )}
                                        {element.type === 'email' && (
                                            <TextField
                                                fullWidth
                                                label={element.label}
                                                name={element.id}
                                                type="email"
                                                value={formData[element.id] || ''}
                                                onChange={handleChange}
                                                variant="outlined"
                                                required
                                                sx={{
                                                    backgroundColor: 'white',
                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: 'black',
                                                        opacity: 1,
                                                    },
                                                }}
                                            />
                                        )}
                                        {element.type === 'number' && (
                                            <TextField
                                                fullWidth
                                                label={element.label}
                                                name={element.id}
                                                type="number"
                                                value={formData[element.id] || ''}
                                                onChange={handleChange}
                                                variant="outlined"
                                                required
                                                sx={{
                                                    backgroundColor: 'white',
                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: 'black',
                                                        opacity: 1,
                                                    },
                                                }}
                                            />
                                        )}
                                        {element.type === 'comment' && (
                                            <TextField
                                                fullWidth
                                                label={element.label}
                                                name={element.id}
                                                value={formData[element.id] || ''}
                                                onChange={handleChange}
                                                variant="outlined"
                                                multiline
                                                rows={4}
                                                sx={{
                                                    backgroundColor: 'white',
                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                    '& .MuiInputBase-input::placeholder': {
                                                        color: 'black',
                                                        opacity: 1,
                                                    },
                                                }}
                                            />
                                        )}
                                        {element.type === 'dropdown' && (
                                            <MDSelect
                                                fullWidth
                                                label={element.label}
                                                name={element.id}
                                                value={formData[element.id] || ''}
                                                onChange={handleChange}
                                                variant="outlined"
                                                required
                                                sx={{
                                                    backgroundColor: 'white',
                                                    flexGrow: 1,
                                                    height: 50,
                                                    borderRadius: '6px',
                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                }}
                                            >
                                                {element.options.map((option, index) => (
                                                    <MenuItem key={index} value={option}>
                                                        {option}
                                                    </MenuItem>
                                                ))}
                                            </MDSelect>
                                        )}
                                        {element.type === 'checkbox' && (
                                            <MDBox
                                                sx={{
                                                    backgroundColor: 'white',
                                                    padding: 2,
                                                    borderRadius: 1,
                                                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                                                }}
                                            >
                                                <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: 1 }}>
                                                    <FormLabel component="legend" sx={{ fontSize: '14px', fontWeight: '500' }}>{element.label}</FormLabel>
                                                    {element.options.map((option, index) => (
                                                        <FormControlLabel
                                                            key={index}
                                                            control={<Checkbox name={element.id} value={option} checked={formData[element.id]?.includes(option)} onChange={handleChange} />}
                                                            label={option}
                                                            sx={{ fontSize: '14px', fontWeight: '500' }}
                                                        />
                                                    ))}
                                                </FormControl>
                                            </MDBox>
                                        )}
                                        {element.type === 'radio' && (
                                            <Box sx={{ backgroundColor: 'white', padding: 2, borderRadius: 1, boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px' }}>
                                                <FormControl component="fieldset" sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: 1 }}>
                                                    <FormLabel component="legend" sx={{ fontSize: '14px', fontWeight: '500' }}>{element.label}</FormLabel>
                                                    <RadioGroup
                                                        aria-label={element.name}
                                                        name={element.id}
                                                        value={formData[element.id] || ''}
                                                        onChange={handleChange}
                                                        row
                                                    >
                                                        {element.options.map((option, index) => (
                                                            <FormControlLabel
                                                                key={index}
                                                                value={option}
                                                                control={<Radio />}
                                                                label={option}
                                                                sx={{ fontSize: '14px', fontWeight: '500' }}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>
                                        )}
                                    </MDBox>
                                </Grid>
                            ))}
                            <Grid item xs={12}>
                                <MDButton
                                    type="submit"
                                    variant="contained"
                                    sx={{
                                        backgroundColor: 'black',
                                        color: 'white',
                                        '&:hover': {
                                            backgroundColor: 'white',
                                            color: 'black',
                                        },
                                    }}
                                    fullWidth
                                >
                                    Submit
                                </MDButton>
                            </Grid>
                        </Grid>
                    </form>
                </MDBox>
            )}
        </Root>
    );
};

export default SurveyForm;

import { styled } from '@mui/system';
import { position } from 'stylis';

const ScrollContainer = styled('div')(({ theme }) => ({
  maxHeight: 'calc(100vh - 112px)', // Adjust the height based on your layout
  overflowY: 'auto', // Show scrollbar only when needed

  /* Webkit-based browsers */
  '&::-webkit-scrollbar': {
    width: 12,
    // position:"absolute",
    // right:-10,
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.grey[200],
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.grey[400],
    borderRadius: 10,
    border: `3px solid ${theme.palette.grey[200]}`,
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: theme.palette.grey[600],
  },
}));

export default ScrollContainer;

import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import PricingSection from "./PricingSection";


const Pricing = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
     <PricingSection />
    </DashboardLayout>
  )
};

export default Pricing;

import { forwardRef } from "react";
import PropTypes from "prop-types";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";

// Custom styles for MDSelect
import MDSelectRoot from "components/MDSelect/MDSelectRoot";

const MDSelect = forwardRef(
  ({ label, variant, error, success, disabled, helperText, ...rest }, ref) => (
    <FormControl variant={variant} error={error} disabled={disabled} fullWidth>
      {label && <InputLabel>{label}</InputLabel>}
      <MDSelectRoot
        {...rest}
        ref={ref}
        ownerState={{ error, success, disabled }}
        label={label}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
);

// Setting default values for the props of MDSelect
MDSelect.defaultProps = {
  error: false,
  success: false,
  disabled: false,
  label: "",
  variant: "outlined",
  helperText: "",
};

// Typechecking props for the MDSelect
MDSelect.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.oneOf(["standard", "outlined", "filled"]),
  helperText: PropTypes.string,
};

export default MDSelect;

import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDSnackbar from "components/MDSnackbar";
import {
  Container,
  TextField,
  Grid,
  MenuItem,
  Paper,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDSelect from "components/MDSelect";
import { useMaterialUIController } from "context";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getViewTemplateData,sendEMailtoUsers } from "../../../api/apiCall"

const ViewTemplate = () => {
  const [templates, setTemplates] = useState([]);
  const [formElements, setFormElements] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [tempBgColor, setTempBgColor] = useState("");
  const [selectedTemplateId, setSelectedTemplateId] = useState("");
  const [selectedBatch, setSelectedBatch] = useState("");
  const [batchUsers, setBatchUsers] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selectedBatchUserId, setSelectedBatchUserId] = useState("");
  const [controller] = useMaterialUIController();
  const { darkMode, sidenavColor } = controller;
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [snackbar, setSnackbar] = useState({
    open: false,
    color: "success",
    icon: "check",
    title: "",
    dateTime: "",
    content: "",
  });

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      // const token = sessionStorage.getItem("token");
      debugger;
      const response = await getViewTemplateData();
      debugger;
      setTemplates(response.data.templates);
      setUserData(response.data.userData);
    } catch (error) {
      console.error("Error fetching templates:", error);
    }
  };

  const handleBatchChange = (event) => {
    const selectedBatchName = event.target.value;
    // setSelectedBatch(selectedBatchName);
    const selectedBatchData = userData.find(
      (batch) => batch.batch.name === selectedBatchName
    );
    if (selectedBatchData) {
      setSelectedBatch(selectedBatchData);
      setBatchUsers(selectedBatchData.users);
    } else {
      setBatchUsers([]);
    }
  };

  const handleBatchUserChange = (event) => {
    const selectedUserEmail = event.target.value;
    const selectedUser = batchUsers.find(
      (user) => user.email === selectedUserEmail
    );
    if (selectedUser) {
      setSelectedBatchUserId(selectedUser.email); // Change email to ID if your data includes user IDs
    } else {
      setSelectedBatchUserId("");
    }
  };

  const handleTemplateChange = (event) => {
    const selectedTemplateName = event.target.value;
    setSelectedTemplate(selectedTemplateName);
    const selectedTemplate = templates.find(
      (template) => template.name === selectedTemplateName
    );
    if (selectedTemplate) {
      setSelectedTemplateId(selectedTemplate.id);
      setFormElements(selectedTemplate.template_details);
      setTempBgColor(selectedTemplate.bg_color);
    } else {
      setFormElements([]);
    }
  };

  const handleGenerateUrl = async () => {
    if (!selectedBatch || !selectedBatch.batch || !selectedBatch.batch.id) {
      showSnackbar("info", "Error", "Batch ID is not selected.");
      return;
    }
  
    if (!selectedTemplateId) {
      showSnackbar("info", "Error", "Template ID is not selected.");
      return;
    }
    const data = {
      batch_id: selectedBatch.batch.id, // Corrected property name
      temp_id: selectedTemplateId,
    };
    // const token = sessionStorage.getItem("token");
  
    try {
      const response = await sendEMailtoUsers(data);
      showSnackbar("success", "Success", "Email Sent Succesfully!");
    } catch (error) {
      console.error('Error uploading file:', error);
      showSnackbar("error", "Error", "Error Sending mail. Please try again.");
    }
  };
  

  const showSnackbar = (color, title, content) => {
    setSnackbar({
      open: true,
      color,
      icon: color === "success" ? "check" : "warning",
      title,
      dateTime: new Date().toLocaleString(),
      content,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Container maxWidth="md" sx={{ mt: 4, mb: 4 }} >
        <MDBox sx={{ display: "flex", flexDirection: "column", gap: 2, flexWrap: 'wrap' }}>
          <MDBox
            sx={{
              display: "flex",
              gap: 1,
              mb: 1,
              padding: 2,
              width: isMdDown ? "100%" : "100%",
              mx: "auto",
              height: "auto",
            }}
          >
            <MDSelect
              id="batchSelect"
              value={selectedBatch.name}
              onChange={handleBatchChange}
              label="Select User Batch"
              variant="outlined"
              fullWidth
              size="small"
              sx={{
                flexGrow: 1,
                backgroundColor: "white",
                "& fieldset": {
                  borderColor: "#343434",
                },
                "&:hover fieldset": {
                  borderColor: "#343434",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
                "& .MuiInputLabel": {
                  color: "black",
                },
              }}
            >
              <MenuItem value="">
                <em>Select User Batch</em>
              </MenuItem>
              {userData.map((batch, index) => (
                <MenuItem key={index} value={batch.batch.name}>
                  {batch.batch.name}
                </MenuItem>
              ))}
            </MDSelect>

            {selectedBatch && (
              <MDSelect
                id="userSelect"
                // value={selectedBatchUserId}
                // onChange={handleBatchUserChange}
                label="Batch User List"
                variant="outlined"
                fullWidth
                size="small"
                sx={{
                  flexGrow: 1,
                  backgroundColor: "white",
                  '& fieldset': {
                    borderColor: '#343434',
                  },
                  '&:hover fieldset': {
                    borderColor: '#343434',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'black',
                  },
                  '& .MuiInputLabel': {
                    color: 'black',
                  }
                }}
              >
                <MenuItem value="">
                  <em>User List</em>
                </MenuItem>
                {batchUsers.map((user, index) => (
                  <MenuItem key={index} value={user.email}>
                    {user.name} ({user.email})
                  </MenuItem>
                ))}
              </MDSelect>
            )}

            <MDSelect
              id="templateSelect"
              value={selectedTemplate}
              onChange={handleTemplateChange}
              label="Select Template"
              variant="outlined"
              fullWidth
              size="small"
              sx={{
                flexGrow: 1,
                backgroundColor: "white",
                "& fieldset": {
                  borderColor: "#343434",
                },
                "&:hover fieldset": {
                  borderColor: "#343434",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
                "& .MuiInputLabel": {
                  color: "black",
                },
              }}
            >
              <MenuItem value="">
                <em>Select a template</em>
              </MenuItem>
              {templates.map((template, index) => (
                <MenuItem key={index} value={template.name}>
                  {template.name}
                </MenuItem>
              ))}
            </MDSelect>

            <MDButton
              variant="contained"
              color={sidenavColor}
              onClick={handleGenerateUrl}
              sx={{
                width: isMdDown ? "80%" : "30%",
                height: "auto",
              }}
            >
              Send Email
            </MDButton>
          </MDBox>

          {selectedTemplate && (
            <MDBox
              component={Paper}
              elevation={3}
              sx={{
                padding: 4,
                mt: 3,
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "10px",
                backgroundColor: tempBgColor,
                "& fieldset": {
                  borderColor: "#343434",
                },
                "&:hover fieldset": {
                  borderColor: "#343434",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "black",
                },
              }}
              bgColor={darkMode ? "inherit" : "#f9f9f9"}
            >
              <MDTypography variant="h5" gutterBottom align="center" mb={4}>
                {selectedTemplate}
              </MDTypography>
              <form>
                <Grid container spacing={2}>
                  <MDBox
                    sx={{ flex: 1 }}
                    bgColor={darkMode ? "inherit" : "transparent"}
                    shadow="xxl"
                  >
                    {formElements.map((element, index) => (
                      <Grid item xs={12} key={index}>
                        <MDBox
                          component={Paper}
                          elevation={3}
                          sx={{
                            padding: 3,
                            mt: 3,
                            mx: "auto",
                            backgroundColor: "transparent",
                            boxShadow: 3,
                            borderRadius: 2,
                          }}
                          bgColor={darkMode ? "inherit" : "white"}
                        >
                          {element.type === "full name" && (
                            <TextField
                              type="text"
                              id={element.id}
                              name={element.name}
                              label={element.label}
                              variant="outlined"
                              fullWidth
                              sx={{
                                backgroundColor: "white",
                                "& fieldset": {
                                  borderColor: "#343434",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#343434",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                              }}
                            />
                          )}
                          {element.type === "email" && (
                            <TextField
                              type="email"
                              id={element.id}
                              name={element.name}
                              label={element.label}
                              variant="outlined"
                              fullWidth
                              sx={{
                                backgroundColor: "white",
                                "& fieldset": {
                                  borderColor: "#343434",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#343434",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                              }}
                            />
                          )}
                          {element.type === "number" && (
                            <TextField
                              type="number"
                              id={element.id}
                              name={element.name}
                              label={element.label}
                              variant="outlined"
                              fullWidth
                              sx={{
                                backgroundColor: "white",
                                "& fieldset": {
                                  borderColor: "#343434",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#343434",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                              }}
                            />
                          )}
                          {element.type === "comment" && (
                            <TextField
                              id={element.id}
                              name={element.name}
                              variant="outlined"
                              label={element.label}
                              fullWidth
                              multiline
                              rows={4}
                              sx={{
                                backgroundColor: "white",
                                "& fieldset": {
                                  borderColor: "#343434",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#343434",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                              }}
                            />
                          )}
                          {element.type === "dropdown" && (
                            <MDSelect
                              id={element.id}
                              name={element.name}
                              variant="outlined"
                              label={element.label}
                              fullWidth
                              sx={{
                                backgroundColor: "white",
                                flexGrow: 1,
                                height: 50,
                                borderRadius: "6px",
                                "& fieldset": {
                                  borderColor: "#343434",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#343434",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "black",
                                },
                              }}
                            >
                              {element.options &&
                                element.options.map((option, index) => (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                ))}
                            </MDSelect>
                          )}
                          {element.type === "checkbox" && (
                            <MDBox
                              sx={{
                                backgroundColor: "white",
                                padding: 2,
                                borderRadius: "6px",
                                border: "1.2px solid #343434",
                              }}
                            >
                              <MDTypography
                                variant="subtitle1"
                                sx={{ color: "#343434" }}
                              >
                                {element.label}
                              </MDTypography>
                              {element.options &&
                                element.options.map((option, index) => (
                                  <FormControlLabel
                                    key={index}
                                    control={
                                      <Checkbox
                                        sx={{
                                          color: "#343434",
                                          "&:not(.Mui-checked) .MuiSvgIcon-root":
                                            {
                                              border: "1.2px solid black",
                                              borderRadius: "50%",
                                              "&:hover": {
                                                backgroundColor:
                                                  "rgba(0, 0, 0, 0.08)",
                                              },
                                            },
                                          "&.Mui-checked .MuiSvgIcon-root": {
                                            color: "blue",
                                          },
                                          "&:hover .MuiSvgIcon-root": {
                                            backgroundColor:
                                              "rgba(0, 0, 0, 0.08)",
                                            borderRadius: "50%",
                                          },
                                        }}
                                      />
                                    }
                                    label={option}
                                    name={element.name}
                                    sx={{
                                      "& .MuiFormControlLabel-label": {
                                        color: "#343434",
                                      },
                                    }}
                                  />
                                ))}
                            </MDBox>
                          )}

                          {element.type === "radio" && (
                            <MDBox
                              sx={{
                                backgroundColor: "white",
                                padding: 2,
                                borderRadius: "6px",
                                border: "1.2px solid #343434",
                              }}
                            >
                              <MDTypography
                                variant="subtitle1"
                                sx={{ color: "#343434" }}
                              >
                                {element.label}
                              </MDTypography>
                              <RadioGroup
                                name={element.name}
                                sx={{
                                  padding: "8px",
                                  "& .MuiRadio-root": {
                                    color: "black",
                                    "&.Mui-checked": {
                                      color: "black",
                                    },
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                    },
                                    "&:focus": {
                                      outline: "none",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      border: "1.2px solid black",
                                      borderRadius: "50%",
                                      "&.Mui-checked": {
                                        border: "1.2px solid black",
                                      },
                                    },
                                  },
                                }}
                              >
                                {element.options &&
                                  element.options.map((option, index) => (
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Radio
                                          sx={{
                                            "&.Mui-checked .MuiSvgIcon-root": {
                                              color: "black",
                                            },
                                          }}
                                        />
                                      }
                                      label={option}
                                      value={option}
                                      sx={{
                                        "& .MuiFormControlLabel-label": {
                                          color: "#343434",
                                        },
                                      }}
                                    />
                                  ))}
                              </RadioGroup>
                            </MDBox>
                          )}
                        </MDBox>
                      </Grid>
                    ))}
                  </MDBox>
                </Grid>
              </form>
            </MDBox>
          )}
        </MDBox>
        
      </Container>
      <Footer />
      <MDSnackbar
        color={snackbar.color}
        icon={snackbar.icon}
        title={snackbar.title}
        dateTime={snackbar.dateTime}
        content={snackbar.content}
        close={closeSnackbar}
        bgWhite={snackbar.color === "light"}
        open={snackbar.open}
      />
    </DashboardLayout>
  );
};

export default ViewTemplate;

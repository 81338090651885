import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  TextField,
  Typography,
  IconButton,
  Grid,
  MenuItem,
  Button,
  Paper,
  Box,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  Rating
} from "@mui/material";
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Add as AddIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Rightminisidenav from "examples/Rightminisidenav";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Dropdown from "assets/images/Dropdown.png"
import crossdropdown from "assets/images/crossdropdown.png"
import { createTemplate } from "../../../api/apiCall"
import MDSnackbar from "components/MDSnackbar";

const colorOptions = ["#96d383", "#fef9c3", "#BBDEFB", "#abb5b4", "#D1C4E9"];

const CreateTemplate = () => {
  const [formElements, setFormElements] = useState([]);
  const [labelEditingId, setLabelEditingId] = useState(null);
  const [editingOptionId, setEditingOptionId] = useState(null);
  const [currentOptionEditValue, setCurrentOptionEditValue] = useState("");
  const [dropDownIndexNo, setDropDownIndexNo] = useState(null);
  const [tempName, setTempName] = useState("");
  const [selectedColor, setSelectedColor] = useState(colorOptions[0]);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [open, setOpen] = useState(true);
  const [ratingvalue, setRatingValue] = useState(0);
  const theme = useTheme();
  const [snackbar, setSnackbar] = useState({
    open: false,
    color: "success",
    icon: "check",
    title: "",
    dateTime: "",
    content: "",
  });
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'));
  const handleListItemClick = (text) => {
    const newElement = {
      id: uuidv4(),
      type: text.toLowerCase(),
      label: text,
      name: text.replace(/\s+/g, "").toLowerCase(),
      options: ["dropdown", "checkbox", "radio", "rating"].includes(text.toLowerCase())
        ? []
        : undefined,
    };
    setFormElements([...formElements, newElement]);
    console.log(formElements);
  };

  const setTemplateValue = (event) => {
    setTempName(event.target.value);
  };

  const handleLabelChange = (id, newLabel) => {
    setFormElements(
      formElements.map((element) =>
        element.id === id ? { ...element, label: newLabel } : element
      )
    );
  };

  const handleOptionEditChange = (e) => {
    if (e && e.target) {
      setCurrentOptionEditValue(e.target.value);
    }
  };

  const handleOptionAdd = (id) => {
    setFormElements(
      formElements.map((element) => {
        if (element.id === id) {
          const newOption = `Option ${element.options.length + 1}`;
          return {
            ...element,
            options: [...element.options, newOption],
          };
        }
        return element;
      })
    );
  };

  const handleOptionDelete = (elementId, optionIndex) => {
    setFormElements(
      formElements.map((element) =>
        element.id === elementId
          ? {
            ...element,
            options: element.options.filter(
              (_, index) => index !== optionIndex
            ),
          }
          : element
      )
    );
  };

  const toggleEditOption = (elementId, optionIndex) => {
    const currentEditing = `${elementId}-${optionIndex}`;
    if (editingOptionId === currentEditing) {
      setFormElements(
        formElements.map((element) =>
          element.id === elementId
            ? {
              ...element,
              options: element.options.map((option, index) =>
                index === optionIndex ? currentOptionEditValue : option
              ),
            }
            : element
        )
      );
      setDropDownIndexNo(null);
      setEditingOptionId(null);
      setCurrentOptionEditValue("");
    } else {
      setDropDownIndexNo(optionIndex);
      setEditingOptionId(currentEditing);
      setCurrentOptionEditValue(
        formElements.find((element) => element.id === elementId).options[
        optionIndex
        ]
      );
    }
  };

  const handleElementDelete = (id) => {
    setFormElements(formElements.filter((element) => element.id !== id));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (tempName.trim() === "") {
      showSnackbar("error", "Error", "Template name cannot be blank.");
      return;
    }

    if (formElements.length === 0) {
      showSnackbar("error", "Error", "Form elements cannot be blank.");
      return;
    }

    for (const element of formElements) {
      if (!element.name || element.name.trim() === "" || !element.type || element.type.trim() === "") {
        showSnackbar("error", "Error", "Form elements must have a name and type.");
        return;
      }
    }

    const payload = {
      name: tempName,
      template_details: formElements,
      bg_color: selectedColor,
    };

    try {
      
      const response = await createTemplate(payload);
      console.log("Success:", response.message);
      showSnackbar("success", "Success", response.message);

      setTempName("");
      setFormElements([]);
      setSelectedColor(colorOptions[0]);
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error.message);
      showSnackbar(
        "error",
        "Error",
        "Error creating template: " + (error.response ? error.response.data.message : error.message)
      );
    }
  };


  const showSnackbar = (color, title, content) => {
    setSnackbar({
      open: true,
      color,
      icon: color === "success" ? "check" : "warning",
      title,
      dateTime: new Date().toLocaleString(),
      content,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox
        sx={{
          display: "flex",
          flexDirection: "row",
          height: "calc(100vh - 64px)",
          gap: 2,
          position: "relative",
        }}
      > */}
      <MDBox sx={{ flex: 1, p: 3, overflowY: "auto", }}>
        <MDBox sx={{ display: "flex", flexDirection: isMdDown ? 'column' : 'row', justifyContent: 'center', paddingY: 1, gap: 2, mb: 2 }}>
          <TextField
            variant="outlined"
            placeholder="Enter Template name"
            value={tempName}
            onChange={setTemplateValue}
            sx={{
              width: isMdDown ? '100%' : '40%',
              border: "1px solid black",
              borderRadius: "6px",
            }}
          />
          <MDButton
            variant="contained"
            color="success"
            onClick={handleSubmit}
          >
            Generate Template
          </MDButton>
        </MDBox>
        <MDBox sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
          {colorOptions.map((color) => (
            <Button
              key={color}
              sx={{
                backgroundColor: color,
                width: 40,
                height: 40,
                margin: "0 5px",
                "&:hover": {
                  backgroundColor: color,
                },
              }}
              onClick={() => setSelectedColor(color)}
            />
          ))}
        </MDBox>
        {/* <MDBox
            sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}
            bgColor={darkMode ? "inherit" : "white"}
            shadow="xxl"
          > */}
        {formElements.length > 0 && (
          <MDBox
            component={Paper}
            elevation={3}
            sx={{
              padding: 3,
              mt: 3,
              mx: "auto",
              width: "70%",
              backgroundColor: selectedColor,
              boxShadow: 3,
              borderRadius: 2,
            }}
          >
            <MDTypography variant="h5" gutterBottom align="center">
              {tempName}
            </MDTypography>
            <form>
              <Grid>
                {formElements.map((element) => (
                  <MDBox
                    key={element.id}
                    component="div"
                    sx={{ mb: 2, padding: 2, borderRadius: "10px" }}
                    shadow="lg"
                    coloredShadow="info"
                    bgColor={darkMode ? "inherit" : "white"}
                  >
                    {labelEditingId === element.id ? (
                      <MDBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <TextField
                          value={element.label}
                          onChange={(e) =>
                            handleLabelChange(element.id, e.target.value)
                          }
                          onBlur={() => setLabelEditingId(null)}
                          autoFocus
                          fullWidth
                        />
                        <IconButton onClick={() => setLabelEditingId(null)}>
                          <SaveIcon
                            color={!darkMode ? "inherit" : "primary"}
                          />
                        </IconButton>
                      </MDBox>
                    ) : (
                      <MDBox
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          mb: 1,
                        }}
                      >
                        <MDTypography variant="h6" sx={{ flexGrow: 1 }}>
                          {element.label}
                        </MDTypography>
                        <IconButton
                          onClick={() => setLabelEditingId(element.id)}
                        >
                          <EditIcon
                            color={!darkMode ? "inherit" : "primary"}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() => handleElementDelete(element.id)}
                        >
                          <DeleteIcon
                            color={!darkMode ? "inherit" : "primary"}
                          />
                        </IconButton>
                      </MDBox>
                    )}
                    {element.type === "full name" && (
                      <TextField
                        type="text"
                        id={element.id}
                        name={element.name}
                        variant="outlined"
                        fullWidth
                        sx={{
                          border: "1px solid #232B2B",
                          borderRadius: "6px",
                        }}
                      />
                    )}
                    {element.type === "email" && (
                      <TextField
                        type="email"
                        id={element.id}
                        name={element.name}
                        variant="outlined"
                        fullWidth
                        sx={{
                          border: "1px solid #232B2B",
                          borderRadius: "6px",
                        }}
                      />
                    )}
                    {element.type === "number" && (
                      <TextField
                        type="number"
                        id={element.id}
                        name={element.name}
                        variant="outlined"
                        fullWidth
                        sx={{
                          border: "1px solid #232B2B",
                          borderRadius: "6px",
                        }}
                      />
                    )}
                    {element.type === 'comment' && (
                      <TextField
                        id={element.id}
                        name={element.name}
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        sx={{ border: '1px solid #232B2B', borderRadius: '6px' }}
                      />
                    )}
                    {element.type === 'dropdown' && (
                      <>
                        <TextField
                          select
                          id={element.id}
                          name={element.name}
                          variant="outlined"
                          fullWidth
                          InputProps={{
                            style: {
                              height: '3rem', // Adjust this value as needed
                              borderRadius: '6px',
                              border: '1px solid #232B2B',
                            },
                          }}
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: '20rem', // Adjust this value as needed
                                },
                              },
                            },
                            IconComponent: () => <ArrowDropDownIcon style={{ color: 'black' }} fontSize="medium" />, // Set the dropdown icon color to black
                          }}
                        >
                          {element.options && element.options.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                <span style={{ flexGrow: 1 }}>{option}</span>
                                <IconButton onClick={() => toggleEditOption(element.id, index)}>
                                  <EditIcon />
                                </IconButton>
                                <IconButton onClick={() => handleOptionDelete(element.id, index)}>
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </MenuItem>
                          ))}
                        </TextField>
                        {editingOptionId && editingOptionId.startsWith(element.id) && (
                          <MDBox sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            <TextField
                              value={currentOptionEditValue}
                              onChange={handleOptionEditChange}
                              autoFocus
                              fullWidth
                              sx={{ border: '1px solid #232B2B', borderRadius: '6px' }}
                            />
                            <IconButton onClick={() => toggleEditOption(element.id, dropDownIndexNo)}>
                              <SaveIcon />
                            </IconButton>
                          </MDBox>
                        )}
                        <IconButton onClick={() => handleOptionAdd(element.id)}>
                          <AddIcon />
                        </IconButton>
                      </>
                    )}

                    {element.type === 'checkbox' && (
                      <>
                        {element.options && element.options.map((option, index) => (
                          <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                            <FormControlLabel
                              control={<Checkbox
                                sx={{
                                  color: '#343434',
                                  marginLeft: '8px', // Adjust this value to move the checkbox to the right
                                  '&:not(.Mui-checked) .MuiSvgIcon-root': {
                                    border: '1.5px solid black', // Unchecked state circle border color
                                    '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                    },
                                  },
                                  '&.Mui-checked .MuiSvgIcon-root': {
                                    color: 'blue',
                                  },
                                  '&:hover .MuiSvgIcon-root': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.08)',
                                    borderRadius: '50%',
                                  },
                                }}
                              />}
                              label={
                                editingOptionId === `${element.id}-${index}` ? (
                                  <TextField
                                    value={currentOptionEditValue}
                                    onChange={handleOptionEditChange}
                                    onBlur={() => toggleEditOption(element.id, index)}
                                    autoFocus
                                    fullWidth
                                    sx={{
                                      border: '1px solid #232B2B',
                                      borderRadius: '6px',
                                      '& .MuiInputBase-input': {
                                        padding: '0.5rem 1rem',
                                      },
                                      marginTop: '-4px', // Adjust this value to align the TextField vertically
                                    }}
                                  />
                                ) : (
                                  <Box sx={{ display: 'flex', alignItems: 'center', mt: '-4px' }}>
                                    <span>{option}</span>
                                  </Box>
                                )
                              }
                              sx={{ flexGrow: 1, alignItems: 'center', mt: '-4px' }}
                            />
                            <IconButton onClick={() => toggleEditOption(element.id, index)}>
                              {editingOptionId === `${element.id}-${index}` ? <SaveIcon /> : <EditIcon />}
                            </IconButton>
                            <IconButton onClick={() => handleOptionDelete(element.id, index)}>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        ))}
                        <IconButton onClick={() => handleOptionAdd(element.id)}>
                          <AddIcon />
                        </IconButton>
                      </>
                    )}

                    {element.type === 'radio' && (
                      <>
                        <RadioGroup>
                          {element.options && element.options.map((option, index) => (
                            <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                              <FormControlLabel
                                control={<Radio
                                  sx={{
                                    padding: '8px',
                                    marginLeft: '8px', // Adjust this value to move the radio to the right
                                    '& .MuiRadio-root': {
                                      color: 'black', // Color of the radio button circle in unchecked state
                                      '&.Mui-checked': {
                                        color: 'black', // Color of the checked radio button circle
                                      },
                                      '&:hover': {
                                        backgroundColor: 'transparent', // Disable hover effect
                                      },
                                      '&:focus': {
                                        outline: 'none', // Disable focus outline
                                      },
                                      '& .MuiSvgIcon-root': {
                                        border: '1.2px solid black', // Circle border for unchecked state
                                        borderRadius: '50%',
                                        '&.Mui-checked': {
                                          border: '1.2px solid black', // Circle border for checked state
                                        },
                                      },
                                    },
                                  }}
                                />}
                                label={
                                  editingOptionId === `${element.id}-${index}` ? (
                                    <TextField
                                      value={currentOptionEditValue}
                                      onChange={handleOptionEditChange}
                                      onBlur={() => toggleEditOption(element.id, index)}
                                      autoFocus
                                      fullWidth
                                      sx={{
                                        border: '1px solid #232B2B',
                                        borderRadius: '6px',
                                        '& .MuiInputBase-input': {
                                          padding: '0.5rem 1rem',
                                        },
                                        marginTop: '-4px', // Adjust this value to align the TextField vertically
                                      }}
                                    />
                                  ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: '-4px' }}>
                                      <span>{option}</span>
                                    </Box>
                                  )
                                }
                                sx={{ flexGrow: 1, alignItems: 'center', mt: '-4px' }}
                              />
                              <IconButton onClick={() => toggleEditOption(element.id, index)}>
                                {editingOptionId === `${element.id}-${index}` ? <SaveIcon /> : <EditIcon />}
                              </IconButton>
                              <IconButton onClick={() => handleOptionDelete(element.id, index)}>
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          ))}
                        </RadioGroup>
                        <IconButton onClick={() => handleOptionAdd(element.id)}>
                          <AddIcon />
                        </IconButton>
                      </>
                    )}
                    {element.type === "rating" && (
                      <MDBox
                        component="fieldset"
                        mb={3}
                        border="none"
                      >
                        
                        <Rating
                          name={element.name}
                          value={0}
                          onChange={(event, newValue) => {
                            // handle change logic
                            setRatingValue(newValue);
                          }}
                          sx={{
                            fontSize: "2rem", // Adjust the size as needed
                            color: "#ffb400", // Adjust the color as needed
                          }}
                        />
                      </MDBox>
                    )}


                  </MDBox>
                ))}
              </Grid>
            </form>
          </MDBox>
        )}
        {/* </MDBox> */}
      </MDBox>
      <MDBox position="relative">
        <MDBox
          mt={2}
          mb={2}
          padding={2}
          onClick={() => setOpen(!open)}
          sx={{
            height: "80px",
            width: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            backgroundColor: 'transparent',
            borderRadius: "10px",
            position: "fixed",
            top: "10%",
            right: "0%",
            cursor: "pointer",
          }}
        >
          {open ? (
            <img src={crossdropdown} alt="crossdropdown" width={"40px"} height={"40px"} />
          ) : (<img src={Dropdown} alt="dropdownimage" width="40px" height="40px" />)}
        </MDBox>
        <MDBox
          marginTop="5rem"
          sx={{
            position: "fixed",
            top: "12%",
            right: "0%",
          }}
        >
          {open && <Rightminisidenav onListItemClick={handleListItemClick} />}
        </MDBox>
        {/* </MDBox> */}
      </MDBox>
      <Footer />
      <MDSnackbar
        color={snackbar.color}
        icon={snackbar.icon}
        title={snackbar.title}
        dateTime={snackbar.dateTime}
        content={snackbar.content}
        close={closeSnackbar}
        bgWhite={snackbar.color === "light"}
        open={snackbar.open}
      />
    </DashboardLayout>
  );

};

export default CreateTemplate;

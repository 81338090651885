// import React from "react";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// // import EnhancedTable from "./DataTable";
// import MDBox from "components/MDBox";
// import TableSortAndSelection from "./ViewTable";

// // const headCells = [
// //   { id: "id", numeric: false, disablePadding: true, label: "ID" },
// //   { id: "name", numeric: false, disablePadding: false, label: "Name" },
// //   { id: "calories", numeric: true, disablePadding: false, label: "Calories" },
// //   { id: "fat", numeric: true, disablePadding: false, label: "Fat (g)" },
// //   { id: "carbs", numeric: true, disablePadding: false, label: "Carbs (g)" },
// //   { id: "protein", numeric: true, disablePadding: false, label: "Protein (g)" },
// // ];

// // const rows = [
// //   { id: 1, name: "Cupcake", calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
// //   { id: 2, name: "Donut", calories: 452, fat: 25.0, carbs: 51, protein: 4.9 },
// //   { id: 3, name: "Eclair", calories: 262, fat: 16.0, carbs: 24, protein: 6.0 },
// //   {
// //     id: 4,
// //     name: "Frozen yoghurt",
// //     calories: 159,
// //     fat: 6.0,
// //     carbs: 24,
// //     protein: 4.0,
// //   },
// //   {
// //     id: 5,
// //     name: "Gingerbread",
// //     calories: 356,
// //     fat: 16.0,
// //     carbs: 49,
// //     protein: 3.9,
// //   },
// //   {
// //     id: 6,
// //     name: "Honeycomb",
// //     calories: 408,
// //     fat: 3.2,
// //     carbs: 87,
// //     protein: 6.5,
// //   },
// //   {
// //     id: 7,
// //     name: "Ice cream sandwich",
// //     calories: 237,
// //     fat: 9.0,
// //     carbs: 37,
// //     protein: 4.3,
// //   },
// //   {
// //     id: 8,
// //     name: "Jelly Bean",
// //     calories: 375,
// //     fat: 0.0,
// //     carbs: 94,
// //     protein: 0.0,
// //   },
// //   { id: 9, name: "KitKat", calories: 518, fat: 26.0, carbs: 65, protein: 7.0 },
// //   {
// //     id: 10,
// //     name: "Lollipop",
// //     calories: 392,
// //     fat: 0.2,
// //     carbs: 98,
// //     protein: 0.0,
// //   },
// // ];

// const ViewSurveyData = () => {
//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox pt={6}>
//         {/* <EnhancedTable headCells={headCells} rows={rows} /> */}
//         <TableSortAndSelection />
//       </MDBox>
//       <Footer />
//     </DashboardLayout>
//   );
// };

// export default ViewSurveyData;

import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DataTable from "./ViewTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import EnhancedTable from "./DataTable";

const headCells = [
  { id: "id", numeric: false, disablePadding: true, label: "ID" },
  {
    id: "firstName",
    numeric: false,
    disablePadding: false,
    label: "First Name",
  },
  { id: "lastName", numeric: false, disablePadding: false, label: "Last Name" },
  { id: "age", numeric: true, disablePadding: false, label: "Age" },
];

const rows = [
  { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
  { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
  { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
  { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
  { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  { id: 6, lastName: "Melisandre", firstName: null, age: 150 },
  { id: 7, lastName: "Clifford", firstName: "Ferrara", age: 44 },
  { id: 8, lastName: "Frances", firstName: "Rossini", age: 36 },
  { id: 9, lastName: "Roxie", firstName: "Harvey", age: 65 },
  { id: 10, lastName: "Baratheon", firstName: "Robert", age: 41 },
  { id: 11, lastName: "Stark", firstName: "Sansa", age: 21 },
  { id: 12, lastName: "Tully", firstName: "Catelyn", age: 40 },
  { id: 13, lastName: "Martell", firstName: "Ellaria", age: 38 },
  { id: 14, lastName: "Tyrell", firstName: "Margaery", age: 27 },
  { id: 15, lastName: "Bolton", firstName: "Ramsay", age: 32 },
];

const ViewSurveyData = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3}>
        <EnhancedTable headCells={headCells} rows={rows} />
      </MDBox>
    </DashboardLayout>
  );
};

export default ViewSurveyData;

import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";
import { uploadBulkUserForsurvey } from "../../../api/apiCall";
import MDSelect from "components/MDSelect";
import { MenuItem } from "@mui/material";
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MDTypography from "components/MDTypography";
import EnhancedTable from "../EnhancedTable";

// ------------- table component imports ---------------

// CustomTabPanel component
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// dummy data
const batchlistdata = [
  {
    batch: {
      name: "Batch A",
      id: 1,
      users: [
        { email: "user1@example.com", name: "User One", phone: "123-456-7890" },
        { email: "user2@example.com", name: "User Two", phone: "987-654-3210" }
      ]
    }
  },
  {
    batch: {
      name: "Batch B",
      id: 2,
      users: [
        { email: "user3@example.com", name: "User Three", phone: "555-123-4567" },
        { email: "user4@example.com", name: "User Four", phone: "555-987-6543" }
      ]
    }
  },
  {
    batch: {
      name: "Batch C",
      id: 3,
      users: [
        { email: "user5@example.com", name: "User Five", phone: "444-123-7890" },
        { email: "user6@example.com", name: "User Six", phone: "444-987-3210" }
      ]
    }
  }
];


// CreateUser component
const CreateUser = () => {
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [excelform, setexcelform] = useState({ file: null, batchName: "" });
  const [deleteEmail, setDeleteEmail] = useState("");
  const [error, setError] = useState("");
  const [selectedBatch, setSelectedBatch] = useState({name: '', id: null});
  const [batchlist, setBatchlist] = useState(batchlistdata);
  const [userData, setUserData] = useState([]);
  const [selectedBatchUserId, setSelectedBatchUserId] = useState("");
  const theme=useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));
  const [snackbar, setSnackbar] = useState({
    open: false,
    color: "success",
    icon: "check",
    title: "",
    dateTime: "",
    content: "",
  });

  const showSnackbar = (color, title, content) => {
    setSnackbar({
      open: true,
      color,
      icon: color === "success" ? "check" : "warning",
      title,
      dateTime: new Date().toLocaleString(),
      content,
    });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  // Handle single submit
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleBatchChange = (event) => {
    const selectedBatchName = event.target.value;
    // setSelectedBatch(selectedBatchName);
    const selectedBatch=batchlist.find(batch=>batch.batch.name===selectedBatchName);
    if (selectedBatch) {
      setSelectedBatch(selectedBatch.batch);
    }else{
      setSelectedBatch({name: '', id: null});
    }
  
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    setFormData({
      name: "",
      email: "",
      phone: "",
    });
    showSnackbar("success", "Success", "User created successfully!");
  };

  // Handle Excel tab submit
  const handleExcelChange = (event) => {
    const file = event.target.files[0];
    const validTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ];

    if (file && validTypes.includes(file.type)) {
      setexcelform((prevForm) => ({ ...prevForm, file }));
      setError("");
    } else {
      setexcelform((prevForm) => ({ ...prevForm, file: null }));
      setError("Please upload a valid Excel file.");
    }
  };

  const handleBatchNameChange = (e) => {
    const { value } = e.target;
    setexcelform((prevForm) => ({ ...prevForm, batchName: value }));
  };

  const handleExcelTabSubmit = async (e) => {
    e.preventDefault();
    if (excelform.file) {
      const formData = new FormData();
      formData.append("file", excelform.file);
      formData.append("batchName", excelform.batchName);

      const token = sessionStorage.getItem("token");
      try {
        const response = await uploadBulkUserForsurvey(formData, token);
        showSnackbar("success", "Success", "File uploaded successfully!");
        console.log("Response Data:", response);
      } catch (error) {
        showSnackbar("error", "Error", "Error uploading file. Please try again.");
      }
    } else {
      showSnackbar("error", "Error", "Please select a file to upload.");
    }
  };

  const handleDeleteChange = (e) => {
    setDeleteEmail(e.target.value);
  };

  const handleDeleteSubmit = (e) => {
    e.preventDefault();
    console.log("Deleting user with email:", deleteEmail);
    setDeleteEmail("");
    showSnackbar("success", "Success", "User deleted successfully!");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox sx={{ width: "100%", mx: "auto" }}>
        <MDBox
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="basic tabs example"
            sx={{ width: "80%", backgroundColor: "#b0b0b0" }}
          >
            <Tab label="Bulk Upload" {...a11yProps(0)} disableRipple />
            <Tab label="Single Upload" {...a11yProps(1)} disableRipple />
            <Tab label="Delete User" {...a11yProps(2)} disableRipple />
          </Tabs>
        </MDBox>
        <MDBox sx={{ display: "flex", justifyContent: "center" }}>
          <CustomTabPanel value={value} index={0}>
            <form onSubmit={handleExcelTabSubmit}>
              <TextField
                type="file"
                name="file"
                variant="outlined"
                margin="normal"
                inputProps={{
                  accept:
                    ".xls, .xlsx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                }}
                onChange={handleExcelChange}
                fullWidth
                required
                sx={{
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "none",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#6F7E8C",
                    },
                    "&:hover fieldset": {
                      borderColor: "#B2BAC2",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#6F7E8C",
                    },
                  },
                }}
              />
              <TextField
                type="text"
                name="batchName"
                label="Batch Name"
                variant="outlined"
                margin="normal"
                value={excelform.batchName}
                onChange={handleBatchNameChange}
                fullWidth
                required
              />
              {error && (
                <Typography color="error" variant="body2">
                  {error}
                </Typography>
              )}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                sx={{ mt: 2, color: "#F0F2F5" }}
              >
                Upload
              </Button>
            </form>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                maxWidth: "580px",
                margin: "auto",
                mt: 4,
                p: 2,
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}
            >
              <Typography sx={{ mb: 2, bgcolor: "#B2BAC2", height: "4rem",width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                Create User
              </Typography>
              <MDBox 
              sx={{
                display: "flex",
                gap: 1,
                mb: 1,
                padding: 2,
                width: isMdDown ? "100%" : "100%",
                mx: "auto",
                height: "5rem",
              }}>
              <MDSelect
              id="batchSelect"
              value={selectedBatch.name}
              onChange={handleBatchChange}
              label="Select User Batch"
              variant="outlined"
              fullWidth
              size="small"
              sx={{
                flexGrow: 1,
                backgroundColor: "white",
                
              }}
            >
              <MenuItem value="">
                <em>Select User Batch</em>
              </MenuItem>
              {/* // todo creating a newbatch state and object */}
              <MenuItem value="">   
                Create New Batch
              </MenuItem>
              {batchlist.map((batch, index) => (
                <MenuItem key={index} value={batch.batch.name}>
                  {batch.batch.name}
                </MenuItem>
              ))}
            </MDSelect>
              </MDBox>
              <form onSubmit={handleSubmit}>
                <TextField
                  type="text"
                  name="name"
                  label="Name"
                  variant="outlined"
                  margin="normal"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  type="email"
                  name="email"
                  label="Email"
                  variant="outlined"
                  margin="normal"
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <TextField
                  type="tel"
                  name="phone"
                  label="Phone Number"
                  variant="outlined"
                  margin="normal"
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  required
                />
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                  sx={{ mt: 2, color: "#F0F2F5" }}
                >
                  Create
                </Button>
              </form>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "auto",
                minWidth: "500px",
                width:"auto",
                mt: 4,
                p: 2,
                border: "1px solid #ccc",
                borderRadius: "8px",
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                Delete User
              </Typography>
              <MDBox 
              sx={{
                display: "flex",
                gap: 1,
                mb: 1,
                padding: 2,
                width: isMdDown ? "100%" : "100%",
                mx: "auto",
                height: "5rem",
                width: "100%",
              }}>
              <MDSelect
              id="batchSelect"
              value={selectedBatch.name}
              onChange={handleBatchChange}
              label="Select User Batch"
              variant="outlined"
              fullWidth
              size="small"
              sx={{
                flexGrow: 1,
                backgroundColor: "white",
                
              }}
            >
              <MenuItem value="">
                <em>Select User Batch</em>
              </MenuItem>
              {batchlist.map((batch, index) => (
                <MenuItem key={index} value={batch.batch.name}>
                  {batch.batch.name}
                </MenuItem>
              ))}
            </MDSelect>
              </MDBox>

              {selectedBatch.name !== "" && (
                
                <EnhancedTable  />
              )}
              
            </Box>
          </CustomTabPanel>
        </MDBox>
      </MDBox>
      <MDSnackbar
        color={snackbar.color}
        icon={snackbar.icon}
        title={snackbar.title}
        dateTime={snackbar.dateTime}
        content={snackbar.content}
        close={closeSnackbar}
        bgWhite={snackbar.color === "light"}
        open={snackbar.open}
      />
    </DashboardLayout>
  );
};

export default CreateUser;



// import { useEffect } from "react";

// // react-router-dom components
// import { useLocation } from "react-router-dom";

// // prop-types is a library for typechecking of props.
// import PropTypes from "prop-types";

// // Material Dashboard 2 React components
// import MDBox from "components/MDBox";

// // Material Dashboard 2 React context
// import { useMaterialUIController, setLayout } from "context";

// function DashboardLayout({ children }) {
//   const [controller, dispatch] = useMaterialUIController();
//   const { miniSidenav } = controller;
//   const { pathname } = useLocation();

//   useEffect(() => {
//     setLayout(dispatch, "dashboard");
//   }, [pathname]);

//   return (
//     <MDBox
//       sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
//         p: 3,
//         position: "relative",

//         [breakpoints.up("xl")]: {
//           marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
//           transition: transitions.create(["margin-left", "margin-right"], {
//             easing: transitions.easing.easeInOut,
//             duration: transitions.duration.standard,
//           }),
//         },
//       })}
//     >
//       {children}
//     </MDBox>
//   );
// }

// // Typechecking props for the DashboardLayout
// DashboardLayout.propTypes = {
//   children: PropTypes.node.isRequired,
// };

// export default DashboardLayout;



import React, { useEffect, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useMaterialUIController, setLayout, setOpenConfigurator } from "context";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import Icon from "@mui/material/Icon";
import Businesslogo from "assets/images/Businesslogo.png";
import RoutesConfig from "routes";
;
function DashboardLayout({ children }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  const { pathname } = useLocation();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const routes=RoutesConfig();
  const token=useSelector((state) => state.auth.token);
  console.log(token)
  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  const handleConfiguratorOpen = useCallback(
    () => setOpenConfigurator(dispatch, !openConfigurator),
    [openConfigurator, dispatch]
  );

  const configsButton = useMemo(
    () => (
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="3.25rem"
        height="3.25rem"
        bgColor="white"
        shadow="sm"
        borderRadius="50%"
        position="fixed"
        right="2rem"
        bottom="2rem"
        zIndex={99}
        color="dark"
        sx={{ cursor: "pointer" }}
        onClick={handleConfiguratorOpen}
      >
        <Icon fontSize="small" color="inherit">
          settings
        </Icon>
      </MDBox>
    ),
    [handleConfiguratorOpen]
  );

  return (
    <>
      {isAuthenticated && layout === "dashboard" && !pathname.includes("/survey") && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? Businesslogo : Businesslogo}
            brandName="Shiavnshki"
            routes={routes}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      <MDBox
        sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
          p: 3,
          position: "relative",
          [breakpoints.up("xl")]: {
            marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
            transition: transitions.create(["margin-left", "margin-right"], {
              easing: transitions.easing.easeInOut,
              duration: transitions.duration.standard,
            }),
          },
        })}
      >
        {children}
      </MDBox>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;

// data/pieChartData.js
// const pieChartData = {
//     labels: ['Positive', 'Negative ', 'Neutral'],
//     datasets: {
//       label: 'Percent',
//       backgroundColors: ['success', 'error', 'warning'],
//       data: [50, 20, 30],
//     },
//   };
  
//   export default pieChartData;
  
const pieChartData = {
  week: {
    labels: ['Surveys Conducted', 'Surveys Attempted'],
    datasets: {
      label: 'Count',
      backgroundColors: ['warning', 'info'],
      data: [150, 120],
    },
  },
  month: {
    labels: ['Surveys Conducted', 'Surveys Attempted'],
    datasets: {
      label: 'Count',
      backgroundColors: ['warning', 'success'],
      data: [600, 480],
    },
  },
  quarter: {
    labels: ['Surveys Conducted', 'Surveys Attempted'],
    datasets: {
      label: 'Count',
      backgroundColors: ['warning', 'secondary'],
      data: [1800, 1440],
    },
  },
  year: {
    labels: ['Surveys Conducted', 'Surveys Attempted'],
    datasets: {
      label: 'Count',
      backgroundColors: ['warning', 'secondary'],
      data: [7200, 5760],
    },
  },
};

export default pieChartData;

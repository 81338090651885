import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import PropTypes from "prop-types";
import { useMaterialUIController } from "context";

const newSideNavColor = [
  { primary: '#d81b60' },
  { secondary: "#495361" },
  { info: '#1b74e9' },
  { success: '#43a047' },
  { warning: '#fb8c00' },
  { error: '#e53935' },
  { dark: '#191919' },
];

const getbgColor = (sidenavColor, newColor) => {
  let requiredColor;
  newColor.map(color => {
    const key = Object.keys(color)[0];
    const colorValues = color[key];
    if (sidenavColor === key) {
      requiredColor = colorValues;
    }
  });
  return requiredColor;
}

// Styled Box component
const StyledBox = styled(Box)(({ theme }) => ({
  width: 190,
  height: "auto", // Adjusted height to fit content
  paddingBottom: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
  overflowY: "auto",
  borderRadius: 8,
  boxShadow: theme.shadows[3],
}));

const DrawerHeader = styled("Box")(({ theme, bgcolor }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
 
  backgroundColor: bgcolor,
  color: theme.palette.common.white,
  height: "55px",
}));

const CenteredListItemText = styled(ListItemText)(({ theme }) => ({
  textAlign: "left", // Align text to the left
  marginLeft: theme.spacing(2), // Add left margin to align text
}));

const Rightminisidenav = ({ onListItemClick }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { sidenavColor, darkMode } = controller;
  const bgColor = getbgColor(sidenavColor, newSideNavColor);

  return (
    <StyledBox>
      <DrawerHeader bgcolor={bgColor}>Form Items</DrawerHeader>
      <List>
        {[
          "Full Name",
          "Email",
          "Number",
          "Comment",
          "DropDown",
          "Checkbox",
          "Radio",
          "Rating",
        ].map((text, index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton onClick={() => onListItemClick(text)}>
              <CenteredListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </StyledBox>
  );
};

Rightminisidenav.propTypes = {
  onListItemClick: PropTypes.func.isRequired,
};

export default Rightminisidenav;

















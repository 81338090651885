// data/defaultLineChartData.js
const defaultLineChartData = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Total",
        data: [65, 59, 80, 81, 56, 55, 70],
        color: "success", 
      },
      {
        label: "Attempted",
        data: [28, 48, 40, 19, 11, 27, 40],
        color: "info", 
      },
    ],
  };
  
  export default defaultLineChartData;
  